/* Hoexr | Hotel agency HTML Template

Template Name: Hoexr
Version: 1.0.0
License: copyright commercial
/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global Settings
4. Main Header / TwO
5. Sticky Header 
6. Mobile Menu
7. Section Title
8. Main Slider
9. Banner Section
10. Project Section
11. Features Section / TwO / Three / Four / Five
12. About Section / Two
13. Services Section / Two
14. Call To Action / Two
15. FAQ's Sectiom
16.  Marquee Section
17. Fun Fact Section
18. Testimonial Section / Two
19. team Section 
20, Contact Section / Two
21. Why Choose Us / Two
22. News Section / Two
23. Video Section
24. Map Section
25. Clients Section
26. Main Footer
**********************************************/
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Gilda+Display&display=swap");
/* @import url("animate.css"); */
/* @import url("owl.css"); */
/* @import url("jquery.fancybox.min.css"); */
/* @import url("linear.css"); */
/* @import url("fontawesome.css");
@import url("flaticon.css");
@import url("tm-bs-mp.css");
@import url("tm-utility-classes.css"); */
:root {
  --theme-color-light: #ffffff;
  --theme-color-dark: #222222;
  --theme-color-gray: #f6f6f6;
  --theme-color1: #aa8453;
  --theme-color2: #fdece3;
  --theme-color3: #faf7f2;
  --bg-theme-color1: var(--theme-color1);
  --bg-theme-color2: var(--theme-color2);
  --bg-theme-color3: var(--theme-color3);
  --text-color: #666666;
  --headings-color: #222222;
  --review-color: #ffc737;
  --link-color: var(--headings-color);
  --link-hover-color: var(--headings-color);
  --title-font: "Gilda Display", serif;
  --text-font: "Barlow", sans-serif;
  --text-font2: "Barlow Condensed", sans-serif;
  --body-font-size: 16px;
  --body-line-height: 28px;
  --body-font-weight: 400;
  --line-height-heading-h1: 1.2em;
  --line-height-heading: 54px;
  --line-height-heading-small: 1.35em;
  --h1-font-size: 72px;
  --h2-font-size: 54px;
  --h3-font-size: 36px;
  --h4-font-size: 24px;
  --h5-font-size: 22px;
  --h6-font-size: 20px;
  --h1-font-weight: 400;
  --h2-font-weight: 400;
  --h3-font-weight: 400;
  --h4-font-weight: 400;
  --h5-font-weight: 400;
  --h6-font-weight: 400;
  --sec-title-subtitle-font-size: 14px;
  --sec-title-subtitle-color: var(--headings-color);
  --sec-title-subtitle-font-family: var(--text-font);
  --sec-title-subtitle-font-weight: 700;
  --sec-title-subtitle-line-height: 20px;
  --sec-title-color: var(--headings-color);
  --sec-title-font-size: var(--h2-font-size);
  --sec-title-font-family: var(--title-font);
  --sec-title-font-weight: var(--h2-font-weight);
  --theme-light-background: #f2f3f5;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black: #131313;
  --container-width: 1200px;
  --small-container-width: 1000px;
  --large-container-width: 1760px;
  --container-pt: 120px;
  --container-pb: 120px;
}

/*
 * typography.scss
 * -----------------------------------------------
*/
::-moz-selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}
::selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  color: #7c858c;
}

::-moz-input-placeholder {
  color: #7c858c;
}

::-ms-input-placeholder {
  color: #7c858c;
}

body {
  background-color: #fff;
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

p,
.text {
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  text-align: justify;
}

/* -------- Headings ---------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position: relative;
  line-height: var(--line-height-heading-);
}
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: var(--headings-color);
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--line-height-heading-h1);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--line-height-heading-small);
  letter-spacing: 0.15px;
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--h5-font-weight);
  line-height: var(--line-height-heading-small);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--h6-font-weight);
  line-height: var(--line-height-heading-small);
}

/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}
p a:not(.button):not(.btn):hover,
p a:not(.button):not(.btn):focus {
  text-decoration: underline;
}

/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a b,
a strong {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol,
ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b,
strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe {
  border: none !important;
}

/*
 * container.scss
 * -----------------------------------------------
*/
.container .container {
  width: 100%;
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

section > .container,
section > .container-fluid {
  padding-top: var(--container-pt);
  padding-bottom: var(--container-pt);
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: var(--container-width);
  }
}
/*=== Default Form ===*/
.form-control,
.input-text {
  height: calc(2.25rem + 27px);
  padding: 14px 30px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}
.form-control::-webkit-input-placeholder,
.input-text::-webkit-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-moz-placeholder,
.input-text::-moz-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::placeholder,
.input-text::placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
  color: #686a6f;
}
.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
  color: #686a6f;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

/* -------- Dark Theme Styling ---------- */
.dark-layout {
  background-color: var(--theme-color-dark) !important;
  --sec-title-color: var(--theme-color-light);
}
.dark-layout .sticky-header .main-menu .navigation > li > a,
.dark-layout h1,
.dark-layout h2,
.dark-layout h3,
.dark-layout h4,
.dark-layout h5,
.dark-layout h6 {
  color: var(--theme-color-light);
}
.dark-layout .preloader {
  background-color: var(--theme-color-dark);
}
/* .dark-layout .preloader:after {
  background-image: url(../images/logo.png);
} */
.dark-layout .sticky-header {
  background-color: var(--theme-color-dark);
}
.dark-layout .hidden-bar .upper-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links li {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/*** 

====================================================================
Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/
textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

figure.image {
  margin-bottom: 0;
}

.title a {
  color: inherit;
}

.color1 {
  color: var(--theme-color1);
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
}

.large-container {
  position: static;
  max-width: var(--large-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.auto-container {
  position: static;
  max-width: var(--container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.small-container {
  position: static;
  max-width: var(--small-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* .bg-pattern-1 {
  background-image: url(../images/icons/pattern-1.png);
} */

.text-theme-color1 {
  color: var(--theme-color1);
}

.bg-theme-color1 {
  background-color: var(--theme-color1);
}

.text-theme-color2 {
  color: var(--theme-color2);
}

.bg-theme-color2 {
  background-color: var(--theme-color2);
}

/*=======================
    Preloader
=======================*/
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
}
.preloader:after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  margin-left: -75px;
  margin-top: -30px;
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: pulse 1s infinite linear;
  animation: pulse 1s infinite linear;
  /* background-image: url(../images/favicon.png); */
  content: "";
}
.preloader:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  color: var(--theme-color-dark);
  font-weight: 600;
  font-size: 14px;
  font-family: var(--title-font);
  letter-spacing: 2px;
  text-transform: uppercase;
  content: "Loading";
  -webkit-transition: none;
  transition: none;
}

/*=======================
Scroll To Top style
=======================*/
.scroll-to-top {
  width: 17px;
  margin-top: 4px;
  height: 17px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: var(--theme-color1);
  z-index: 100;
  display: none;
  border-radius: 50%;
  color: #000;
  font-size: 10px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.scroll-to-top:hover {
  color: #ffffff;
}

/*======================
    Tabs Box
======================*/
.tabs-box {
  position: relative;
}
.tabs-box .tab {
  display: none;
}
.tabs-box .tab.active-tab {
  display: block;
}

/*=======================
    List Styles
=======================*/
.list-style-one {
  position: relative;
}
.list-style-one li {
  position: relative;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: var(--text-color);
  margin-right: 50px;
  margin-bottom: 7px;
  padding-left: 22px;
}
.list-style-one li .icon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--theme-color1);
}

/*=======================
    List Styles
=======================*/
.list-style-two {
  margin-top: 28px;
}
.list-style-two li {
  font-size: 16px;
  color: var(--text-color);
  position: relative;
  padding-left: 25px;
  margin-bottom: 8px;
}
.list-style-two li .icon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--theme-color1);
}

/*===========================
    Social Icons Styles
============================*/
.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-icon-one li {
  position: relative;
  margin-left: 20px;
}
.social-icon-one li:first-child {
  margin-left: 0;
}
.social-icon-one li a {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 30px;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-one li a:hover {
  color: var(--theme-color1);
}

/*======================
    Play Buttons
======================*/
.play-btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.play-btn .icon {
  position: relative;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--theme-color1);
  background-color: var(--theme-color-light);
  border: 1px solid var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-btn .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 25px;
  text-transform: capitalize;
  padding-left: 30px;
  margin-left: -10px;
  color: var(--theme-color2);
  background: var(--theme-color-gray);
  border-radius: 0px 22px 22px 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-btn:hover .icon {
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
}
.play-btn:hover .title {
  background-color: var(--bg-theme-color2);
  color: var(--theme-color-light);
}

/*======================
    Media Play Button 
======================*/
.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 85px;
  background-color: #ffffff;
  color: var(--bg-theme-color2);
  z-index: 1;
  font-size: 18px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  content: "";
}
.play-now .ripple.light {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
}
.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.play-now .ripple::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}
/*======================
    Carousel Navs
======================*/
.default-navs .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.default-navs .owl-next,
.default-navs .owl-prev {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 20px;
  font-weight: 700;
  line-height: 58px;
  color: var(--theme-color-dark);
  background: #fff;
  border: 1px solid #e3e3e3;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  margin-left: 20px;
}
.default-navs .owl-next:hover,
.default-navs .owl-prev:hover {
  border-color: var(--theme-color1);
}
.default-navs .owl-prev {
  margin-left: 0;
}

.disable-navs .owl-nav {
  display: none;
}

.disable-dots .owl-dots {
  display: none;
}

/*==========================
	Default Tabs
===========================*/
.default-tabs {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.default-tabs .tab-buttons {
  position: relative;
  margin-bottom: 30px;
}
.default-tabs .tab-btn {
  position: relative;
  float: left;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 35px;
  color: var(--theme-color1);
  line-height: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.default-tabs .tab-btn:last-child {
  margin-right: 0;
}
.default-tabs .tab-btn.active-btn {
  background: var(--gradient-1);
  color: #ffffff;
}
.default-tabs .tabs-content {
  position: relative;
  width: 100%;
}

/*================================
    Progress Bar
=================================*/
.skills {
  position: relative;
  margin-bottom: 50px;
}
.skills .skill-item {
  position: relative;
  margin-bottom: 25px;
}
.skills .skill-item:last-child {
  margin-bottom: 0px;
}
.skills .skill-item .skill-header {
  position: relative;
  margin-bottom: 0px;
}
.skills .skill-item .skill-header .skill-title {
  font-weight: 600;
  color: var(--theme-color-dark);
  letter-spacing: 0;
  margin-bottom: 10px;
}
.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
  height: 14px;
  border-radius: 10px;
}
.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 14px;
  background: #f2f3f6;
  border-radius: 10px;
}
.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 14px;
  width: 0px;
  -webkit-transition: all 3000ms ease;
  transition: all 3000ms ease;
  border-radius: 10px;
  background: var(--bg-theme-color1);
}
.skills .skill-item .skill-bar .bar-inner .skill-percentage {
  position: absolute;
  right: 0;
  bottom: 100%;
  font-weight: 400;
  color: #6f7174;
  line-height: 25px;
  margin-bottom: 10px;
}

.before-none:before,
.before_none:before {
  display: none;
}

.after-none:after,
.after_none:after {
  display: none;
}

.overlay-anim {
  position: relative;
}
.overlay-anim:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.overlay-anim:hover:before {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

section {
  position: relative;
}

.theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: var(--text-font2);
}
.theme-btn .btn-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 25px;
  padding: 14px 32px 18px;
  overflow: hidden;
  color: var(--theme-color1);
  border: 1px solid #3c903a;
  background: #f8f5f0;
  text-transform: uppercase;
  z-index: 9;
}
.btn-style-one i {
  position: relative;
  display: block;
  margin-left: 10px;
}
.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: #3c903a;
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.btn-style-one:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.btn-style-one:hover {
  color: #fff;
}

.theme-btn.small {
  padding: 10px 30px;
  line-height: 20px;
  font-size: 10px;
}

.theme-btn.large {
  padding: 20px 60px;
  line-height: 25px;
}

.bounce-y {
  -webkit-animation: bounce-y 10s infinite linear;
  animation: bounce-y 10s infinite linear;
}

@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.btn-anim {
  position: relative;
}
.btn-anim:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.btn-anim:hover:before {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.accordion-box {
  position: relative;
}
.accordion-box .block {
  position: relative;
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}
.accordion-box .block .acc-btn {
  position: relative;
  color: var(--headings-color);
  font-family: var(--title-font);
  font-weight: var(--h4-font-weight);
  font-size: 20px;
  cursor: pointer;
  padding: 20px 30px;
  padding-right: 70px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-bottom: 1px solid #fff;
}
.accordion-box .block .acc-btn .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 50%;
  height: 35px;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 2;
  color: var(--theme-color1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn.active {
  background-color: var(--bg-theme-color1);
  color: #fff;
}
.accordion-box .block .acc-btn.active .icon {
  color: var(--text-color-bg-theme-color2);
}
.accordion-box .block .acc-btn.active .icon:before {
  content: "\f068";
}
.accordion-box .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box .block .acc-content .content {
  position: relative;
  padding: 25px 30px;
}
.accordion-box .block .acc-content .content .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #808287;
  margin-bottom: 0;
}
.accordion-box .block .acc-content.current {
  display: block;
}

/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.search-popup .search-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color-dark);
  opacity: 0.95;
}
.search-popup .close-search {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: var(--theme-color-light);
  z-index: 3;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .search-inner {
  position: relative;
  display: block;
  top: 40%;
  height: auto;
  z-index: 1;
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.search-popup .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-popup .form-group input[type="search"],
.search-popup .form-group input[type="text"] {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e6dc;
  padding: 15px 20px;
  color: #707070;
  background: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group input[type="search"]:focus,
.search-popup .form-group input[type="text"]:focus {
  border-color: var(--border-theme-color2);
}
.search-popup .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  color: var(--theme-color-dark);
  line-height: 40px;
  border-radius: 5px;
  font-weight: normal;
  background: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group button:hover {
  color: var(--theme-color-dark);
}
.search-popup textarea::-webkit-input-placeholder,
.search-popup input::-webkit-input-placeholder {
  color: inherit;
}
.search-popup textarea::-moz-placeholder,
.search-popup input::-moz-placeholder {
  color: inherit;
}
.search-popup textarea:-ms-input-placeholder,
.search-popup input:-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::-ms-input-placeholder,
.search-popup input::-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::placeholder,
.search-popup input::placeholder {
  color: inherit;
}

.moblie-search-active .search-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 0%;
}
.moblie-search-active .search-popup .search-inner {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
}

/*** 

====================================================================
Main Header
====================================================================

***/
.header-span {
  position: relative;
  height: 110px;
  display: block;
  width: 100%;
}

.main-header {
  position: relative;
  width: 100%;
  z-index: 999;
}

.header-top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-top .header-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px 0 10px;
}
.header-top .top-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-top .top-left .info-list {
  color: #ffffff;
  font-size: 14px;
}
.header-top .top-left .info-list span {
  color: #b5cf2b;
  font-size: 14px;
  font-weight: 600;
}
.header-top .top-center {
  margin-left: auto;
  margin-right: auto;
}
.header-top .top-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-top .top-right button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  background: transparent;
}
.header-top .top-right button span,
.header-top .top-right button img {
  margin-right: 10px;
  display: inline-block;
}
.header-top .top-right button span {
  font-size: 20px;
}
.header-top .top-right button.login-btn {
  margin-left: 72px;
}
.header-top .top-right button.login-btn i {
  font-size: 18px;
  margin-right: 7px;
}

.main-header .header-lower {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .header-lower .logo-box {
  position: relative;
}

.main-header {
  width: 100%;
  z-index: 999;
}
.main-header .logo {
  position: relative;
  display: block;
}
.main-header .logo img {
  max-width: 100%;
  height: auto;
}
.main-header .main-box {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .main-box .nav-outer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.main-menu {
  position: relative;
}
@media (max-width: 991.98px) {
  .main-menu {
    display: none;
  }
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  float: left;
  padding: 25px 0px;
  margin-right: 35px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li:last-child {
  margin-right: 0;
}
.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: var(--text-font2);
  letter-spacing: 1.3px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li > a:hover {
  color: var(--theme-color1);
}
.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
  margin-top: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  padding: 10px 0 0;
  background-color: #ffffff;
  margin-top: 30px;
  opacity: 0;
  display: none;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}
.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ebf1f5;
}
.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0px;
  line-height: 29px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.main-menu .navigation > li > ul > li:hover > a {
  color: var(--theme-color1);
}
.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: "Font Awesome 6 Pro";
  content: "\f105";
  position: absolute;
  right: 0;
  top: 11px;
  display: block;
  line-height: 24px;
  font-size: 14px;
  font-weight: 900;
  z-index: 5;
}
.main-menu .navigation > li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0px;
  margin-top: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0px;
  width: 220px;
  z-index: 100;
  display: none;
  background-color: #ffffff;
  opacity: 0;
  padding: 10px 0 0;
  margin-top: 10px;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  border-bottom: 1px solid #ebf1f5;
  width: 100%;
}
.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li > ul > li > ul > li > a:hover {
  color: var(--theme-color1);
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.main-header .outer-box .header-btn {
  display: inline-block;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  padding: 0 5px 0 38px;
  height: 60px;
  line-height: 60px;
  border: 2px solid #fff;
  border-radius: 50px;
  position: relative;
}
.main-header .outer-box .header-btn span {
  margin-left: 10px;
  position: relative;
  top: -2px;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--theme-color1);
}
.main-header .outer-box .header-btn span i {
  color: #000;
  font-size: 22px;
  font-weight: 900;
  top: 4px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header .outer-box .header-btn:hover {
  background-color: var(--theme-color1);
  border-color: transparent;
}

.main-header .ui-btn-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 17px;
  position: relative;
  right: 117px;
}

.main-header .ui-btn {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: none;
  font-size: 26px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .ui-btn:hover {
  color: var(--theme-color1);
}

.main-header .info-btn {
  position: relative;
  font-size: 16px;
  padding-left: 44px;
  color: #ffffff;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  line-height: 20px;
}
.main-header .info-btn small {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--theme-color1);
}
.main-header .info-btn .icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  line-height: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 1em;
  margin-right: 20px;
  padding-right: 20px;
}
.main-header .info-btn:hover {
  color: var(--theme-color-light);
}

/*** 

====================================================================
    header-style-one
====================================================================

***/
.header-style-one {
  position: absolute;
  margin-top: 18px;
}
.header-style-one .main-box {
  border-bottom: 1px solid #5d554f;
  padding-bottom: 20px;
}
.header-style-one .main-box .nav-outer {
  margin-left: 113px;
}
.header-style-one .main-box .nav-outer .nav .navigation li a {
  color: var(--theme-color-light);
}
.header-style-one .main-box .nav-outer .nav .navigation li a:hover {
  color: var(--theme-color1);
}
.header-style-one .main-box .nav-outer .nav .navigation li ul li a {
  color: #000;
}
.header-style-one .main-menu .navigation > li > a {
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
}
.header-style-one .main-menu .navigation > li > a:hover {
  color: var(--theme-color1);
}
.header-style-one .outer-box .search-btn {
  background-color: unset;
  margin-right: 40px;
}
.header-style-one .outer-box .search-btn .flaticon-search {
  font-size: 21px;
  color: #000000;
}
.header-style-one .outer-box .login-btn {
  position: relative;
  margin-right: 18px;
  background-color: unset;
}
.header-style-one .outer-box .login-btn::before {
  content: "";
  position: absolute;
  left: -22px;
  top: -1px;
  width: 1px;
  height: 27px;
  background-color: #000000;
}
.header-style-one .outer-box .login-btn .flaticon-user {
  font-size: 21px;
  color: #000000;
}
.header-style-one .outer-box .header-btn {
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 0px;
  line-height: 48px;
  background-color: var(--theme-color1);
  border-color: transparent;
  padding: 0;
  width: 140px;
  text-align: center;
  margin-top: -8px;
  position: relative;
  top: 5px;
  height: 50px;
  z-index: 9;
  border: 0;
}
.header-style-one .outer-box .header-btn:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background: #000;
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1),
    cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.header-style-one .outer-box .header-btn:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.header-style-one .main-menu .navigation > li {
  padding: 0;
  margin-right: 48px;
}
@media (max-width: 1199.98px) {
  .header-style-one .main-menu .navigation > li {
    margin-right: 38px;
  }
}
.header-style-one .main-menu .navigation > li:last-child {
  margin-right: 0;
}
.header-style-one .main-menu .navigation li.dropdown .dropdown-btn {
  display: inline-block;
  border: 2px;
  top: auto;
  bottom: 1px;
  right: -14px;
  width: auto;
  height: unset;
  font-size: 10px;
  color: #8f8f8f;
}
.header-style-one .main-menu .navigation li.dropdown ul .dropdown-btn {
  display: none;
}
.header-style-one .mobile-nav-toggler {
  -webkit-box-ordinal-group: unset;
  -ms-flex-order: unset;
  order: unset;
  margin-right: 0;
  display: none;
}
.header-style-one .mobile-nav-toggler span {
  color: #fff;
}
.header-style-one .main-menu .navigation > li > ul > li > a:hover {
  color: var(--theme-color-v2);
}
.header-style-one .sticky-header .mobile-nav-toggler {
  display: none;
}
.header-style-one .sticky-header .main-menu .navigation > li.current > a,
.header-style-one .sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color-v2);
}
.header-style-one .sticky-header .main-menu .navigation > li {
  padding: 0;
}
.header-style-one .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-style-one .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-style-one .sticky-header .main-menu .navigation > li.current > a,
.header-style-one .sticky-header .main-menu .navigation > li:hover > a {
  color: #fff;
}
.header-style-one
  .sticky-header
  .main-menu
  .navigation
  li.dropdown
  .dropdown-btn {
  color: #fff;
}
.header-style-one .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
  margin-top: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
}
/*** 

====================================================================
    header-style-two
====================================================================

***/
.header-style-two {
  position: absolute;
}
.header-style-two .header-top {
  display: unset;
}
.header-style-two .header-top .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 0 11px;
}
.header-style-two .header-top .inner-box .top-left span {
  color: #adadad;
}
.header-style-two .header-top .inner-box .top-left span .icon {
  color: var(--theme-color1);
  margin-right: 18px;
}
.header-style-two .header-top .inner-box .top-right .social-icon-one li a {
  font-size: 16px;
  color: #adadad;
}
.header-style-two .header-top .social-icon-one li {
  color: #adadad;
  margin-left: 0;
  margin-right: 18px;
}
.header-style-two .header-top .social-icon-one li:last-child {
  margin-right: 0;
}
.header-style-two .outer-box {
  margin-left: 0;
}
.header-style-two .main-box {
  background-color: var(--theme-color-light);
  padding: 24px 36px 24px 40px;
}
@media (max-width: 991.98px) {
  .header-style-two .main-box {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.header-style-two .main-box .nav-outer {
  margin-left: auto;
  margin-right: 60px;
}
.header-style-two .main-box .nav-outer .nav .navigation li {
  margin-right: 49px;
}
.header-style-two .main-box .nav-outer .nav .navigation li:last-child {
  margin-right: 0;
}
.header-style-two .main-box .nav-outer .nav .navigation li a:hover {
  color: var(--theme-color1);
}
.header-style-two .main-menu .navigation > li > a {
  color: #000000;
  text-transform: uppercase;
  position: relative;
}
.header-style-two .main-menu .navigation > li > a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #000000;
  height: 1px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header-style-two .main-menu .navigation > li > a:hover {
  color: var(--theme-color1);
}
.header-style-two .main-menu .navigation > li > a:hover:before {
  width: 100%;
}
.header-style-two .outer-box .search-btn {
  position: relative;
  background-color: unset;
  margin-right: 149px;
}
@media (max-width: 767.98px) {
  .header-style-two .outer-box .search-btn {
    margin-right: 100px;
  }
}
.header-style-two .outer-box .search-btn::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -52px;
  width: 1px;
  height: 40px;
  background-color: #ababab;
}
.header-style-two .outer-box .search-btn .flaticon-search {
  font-size: 21px;
  color: #000000;
}
.header-style-two .outer-box .login-btn {
  position: relative;
  margin-right: 18px;
  background-color: unset;
}
.header-style-two .outer-box .login-btn::before {
  content: "";
  position: absolute;
  left: -22px;
  top: -1px;
  width: 1px;
  height: 27px;
  background-color: #000000;
}
.header-style-two .outer-box .login-btn .flaticon-user {
  font-size: 21px;
  color: #000000;
}
.header-style-two .outer-box .header-btn {
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 0px;
  line-height: 45px;
  background-color: var(--theme-color1);
  border-color: transparent;
  padding: 0;
  width: 140px;
  text-align: center;
  margin-top: -8px;
  height: 50px;
}
.header-style-two .outer-box .header-btn:before {
  display: none;
}
.header-style-two .main-menu .navigation > li {
  padding: 0;
  margin-right: 58px;
}
.header-style-two .main-menu .navigation > li:last-child {
  margin-right: 0;
}
.header-style-two .main-menu .navigation li.dropdown .dropdown-btn {
  display: inline-block;
  border: 2px;
  top: auto;
  bottom: 1px;
  right: -14px;
  width: auto;
  height: unset;
  font-size: 10px;
  color: #8f8f8f;
}
.header-style-two .main-menu .navigation li.dropdown ul .dropdown-btn {
  display: none;
}
.header-style-two .mobile-nav-toggler {
  display: inline-block;
  -webkit-box-ordinal-group: unset;
  -ms-flex-order: unset;
  order: unset;
  font-size: 24px;
  color: #000;
  margin-top: 3px;
}
.header-style-two .main-menu .navigation > li > ul > li > a:hover {
  color: var(--theme-color-v2);
}
.header-style-two .sticky-header {
  background-color: var(--theme-color-v2);
  /*.main-menu .navigation > li.current > a,*/
}
.header-style-two .sticky-header .mobile-nav-toggler {
  display: none;
}
.header-style-two .sticky-header .main-menu .navigation > li.current > a,
.header-style-two .sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color-v2);
}
.header-style-two .sticky-header .main-menu .navigation > li {
  padding: 0;
}
.header-style-two .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
}
.header-style-two .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-style-two
  .sticky-header
  .main-menu
  .navigation
  li.dropdown
  .dropdown-btn {
  color: #fff;
}
.header-style-two .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*** 

====================================================================
Sticky Header
====================================================================

***/
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99999;
  background: #000;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.sticky-header .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sticky-header.fixed-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

.sticky-header .logo {
  padding: 10px 0;
}
.sticky-header .logo img {
  max-height: 40px;
}
.sticky-header .nav-outer {
  position: relative;
  background: none;
  margin-left: auto;
}

.sticky-header .main-menu .navigation > li {
  margin: 0;
  margin-left: 60px;
  padding: 20px 0;
}

.sticky-header .main-menu .navigation > li > a {
  color: #fff;
}

.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color1);
}

.sticky-header .outer-box,
.sticky-header .navbar-header {
  display: none;
}

.header-style-two .sticky-header .main-menu .navigation > li > a {
  color: #000;
}
.header-style-two .sticky-header .main-menu .navigation > li > a:hover {
  color: var(--theme-color1);
}

@media only screen and (max-width: 1200px) {
  .header-style-one .main-box .nav-outer {
    margin-left: 70px;
    margin-right: auto;
  }
  .header-style-two .main-box .nav-outer .nav .navigation li {
    margin-right: 26px;
  }
  .header-style-two .main-box .nav-outer {
    margin-right: 40px;
  }
  .header-style-two .outer-box .search-btn {
    margin-right: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .header-style-one .mobile-nav-toggler,
  .header-style-two .sticky-header .mobile-nav-toggler,
  .header-style-one .sticky-header .mobile-nav-toggler {
    display: inline-block;
  }
  .header-style-two .sticky-header .main-menu,
  .header-style-one .sticky-header .main-menu {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .header-style-one .outer-box .header-btn {
    display: none;
  }
}
/*** 

====================================================================
      Mobile Menu
====================================================================

***/
.mobile-nav-toggler {
  position: relative;
  font-size: 28px;
  line-height: 20px;
  cursor: pointer;
  color: var(--theme-color1);
  display: none;
  top: -3px;
  margin-left: 20px;
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
@media (max-width: 991.98px) {
  .mobile-nav-toggler {
    display: block;
  }
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}
.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
}
.mobile-menu .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.mobile-menu .close-btn {
  position: relative;
  top: 0px;
  right: -5px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}
.mobile-menu .close-btn:hover {
  opacity: 0.5;
}
.mobile-menu .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}
.mobile-menu .nav-logo img {
  max-height: 50px;
}
.mobile-menu .menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: #222;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease 200ms;
  transition: all 0.4s ease 200ms;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.mobile-menu-visible .scroll-to-top {
  opacity: 0;
  visibility: hidden;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > ul > li:last-child {
  border-bottom: none;
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
}
.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a {
  color: #ffffff;
}
.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
}
.mobile-menu .navigation li.dropdown .dropdown-btn:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 10px;
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li.dropdown .dropdown-btn.active i:before {
  content: "\f106";
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}

.mobile-menu .navigation li > ul > li {
  padding-left: 20px;
}

.contact-list-one {
  position: relative;
  padding: 30px 20px 20px;
}
.contact-list-one li {
  position: relative;
  padding-left: 54px;
  min-height: 50px;
  margin-bottom: 20px;
}
.contact-list-one li .icon {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 34px;
  line-height: 50px;
  color: #ffffff;
}
.contact-list-one li .title {
  display: block;
  font-size: 12px;
  color: var(--theme-color1);
  font-weight: 400;
  text-transform: uppercase;
}
.contact-list-one li a {
  color: #fff;
}
.contact-list-one li .text {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.contact-list-one li .text a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-info-box {
  color: #fff;
}

.mobile-menu .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #222;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}
.mobile-menu .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.mobile-menu .social-links li a:hover {
  color: var(--theme-color2);
}

/***

====================================================================
  Hidden Sidebar style
====================================================================

***/
.toggle-hidden-bar {
  position: relative;
}

.hidden-bar {
  position: fixed;
  right: 0;
  top: 0px;
  height: 100%;
  overflow-y: auto;
  z-index: 99999;
  max-width: 320px;
  width: 100%;
  opacity: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;
  background: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.hidden-bar::-webkit-scrollbar {
  width: 2px;
}
.hidden-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #cecece;
  box-shadow: inset 0 0 2px #cecece;
  border-radius: 10px;
}
.hidden-bar::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}
.hidden-bar::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}
.hidden-bar .inner-box {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--theme-color-dark);
  height: 100%;
}
.hidden-bar .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.hidden-bar .upper-box .close-btn {
  position: relative;
  top: -5px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  color: var(--theme-color1);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.hidden-bar .upper-box .close-btn:hover {
  opacity: 0.5;
}
.hidden-bar .upper-box .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}
.hidden-bar .upper-box .nav-logo img {
  max-height: 40px;
}
.hidden-bar .text-box {
  position: relative;
  padding: 25px 30px 25px;
}
.hidden-bar .text-box .title {
  color: var(--theme-color-light);
}
.hidden-bar .text-box .text {
  color: var(--theme-color-light);
}

.hidden-bar-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active-hidden-bar .hidden-bar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.active-hidden-bar .hidden-bar-back-drop {
  opacity: 1;
  visibility: visible;
}

.hidden-bar .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--theme-color-dark);
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: auto;
}
.hidden-bar .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.hidden-bar .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.hidden-bar .social-links li a:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
Section Title
====================================================================

***/
.sec-title {
  position: relative;
  margin-bottom: 49px;
}
.sec-title.white .sub-title:before {
  display: none;
}
.sec-title.white h2 {
  color: #fff;
}
.sec-title .sub-title {
  position: relative;
  font-size: 18px;
  color: var(--text-color);
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 14px;
  font-family: var(--text-font2);
  letter-spacing: 7.8px;
}
.sec-title .sub-title::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -50px;
  width: 37px;
  height: 10px;
  /* background-image: url(../images/icons/before.png); */
}
.sec-title .sub-title::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 100%;
  margin-left: 5px;
  width: 37px;
  height: 10px;
  /* background-image: url(../images/icons/before.png); */
}
.sec-title h2 {
  position: relative;
  display: block;
  font-size: var(--sec-title-font-size);
  color: var(--sec-title-color);
  font-family: var(--sec-title-font-family);
  font-weight: var(--sec-title-font-weight);
  margin-bottom: 0;
  z-index: 2;
}
.sec-title .text {
  margin-top: 24px;
}
.sec-title.light .sub-title,
.sec-title.light .text,
.sec-title.light h2,
.sec-title.light h1 {
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  .sec-title h2 br {
    display: none;
  }
  br {
    display: none;
  }
  .sec-title h2 {
    font-size: 44px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-title h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .sec-title .sub-title {
    letter-spacing: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .sec-title .sub-title {
    letter-spacing: 3px;
  }
}
/*** 

====================================================================
  banner section
====================================================================

***/
.banner-section {
  position: relative;
}
.banner-section .banner-slider {
  height: 100vh;
}
.banner-section .banner-slider .slick-list,
.banner-section .banner-slider .slick-track {
  height: 100%;
}
.banner-section .banner-slide {
  position: relative;
  height: 100%;
}
.banner-section .banner-slide .image {
  height: 100%;
}
.banner-section .banner-slide .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.banner-section .content-box {
  position: relative;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 700px;
}
.banner-section .content-box .sub-title {
  position: relative;
  text-transform: uppercase;
  color: var(--theme-color-light);
  margin-bottom: 30px;
  display: inline-block;
  margin-top: 70px;
}
@media (max-width: 575.98px) {
  .banner-section .content-box .sub-title {
    font-size: 14px;
    line-height: 14px;
  }
}
.banner-section .content-box .sub-title::before {
  content: "";
  position: absolute;
  top: -71px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 45px;
  height: 40px;
  /* background-image: url(../images/resource/title-image.png); */
  z-index: 99;
}
.banner-section .content-box h1 {
  color: var(--theme-color-light);
  text-transform: uppercase;
  font-size: 56px;
  margin-bottom: 42px;
}
@media (max-width: 767.98px) {
  .banner-section .content-box h1 {
    font-size: 45px;
  }
}
@media (max-width: 575.98px) {
  .banner-section .content-box h1 {
    font-size: 32px;
    line-height: 46px;
    padding: 0 20px;
    margin-bottom: 20px;
  }
}
.banner-section .content-box .btn {
  color: var(--theme-color-light);
  text-transform: uppercase;
  width: 170px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border: 1px solid var(--theme-color-light);
  position: relative;
  z-index: 9;
}
.banner-section .content-box .btn:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.banner-section .content-box .btn:hover {
  border-color: var(--bg-theme-color1);
}
.banner-section .content-box .btn:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

/*** 

====================================================================
  banner section two
====================================================================

***/
.banner-section-two {
  position: relative;
}
.banner-section-two .slick-dots {
  bottom: 150px;
}
.banner-section-two .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 50%;
  position: relative;
}
.banner-section-two .slick-dots li button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
}
.banner-section-two .slick-dots li.slick-active button {
  border-color: #fff;
}
.banner-section-two .slick-dots li.slick-active button:before {
  opacity: 1;
}
.banner-section-two .banner-slider {
  position: relative;
  min-height: 900px;
  height: 100vh;
}
.banner-section-two .banner-slider .slick-list,
.banner-section-two .banner-slider .slick-track {
  height: 100%;
}
.banner-section-two .banner-slider .banner-slide-v2 {
  position: relative;
  height: 100%;
}
.banner-section-two .banner-slider .banner-slide-v2 .outer-box {
  height: 100%;
}
.banner-section-two .banner-slider .banner-slide-v2 .image {
  height: 100%;
}
.banner-section-two .banner-slider .banner-slide-v2 .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.banner-section-two .banner-slider .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.banner-section-two .banner-slider .content-box .sub-title {
  position: relative;
  text-transform: uppercase;
  color: var(--theme-color-light);
  margin-bottom: 30px;
  display: inline-block;
  margin-top: 70px;
}
@media (max-width: 575.98px) {
  .banner-section-two .banner-slider .content-box .sub-title {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
.banner-section-two .banner-slider .content-box .sub-title::before {
  content: "";
  position: absolute;
  top: -71px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 45px;
  height: 40px;
  /* background-image: url(../images/resource/title-image.png); */
  z-index: 99;
}
.banner-section-two .banner-slider .content-box h1 {
  color: var(--theme-color-light);
  text-transform: uppercase;
  font-size: 56px;
  margin-bottom: 42px;
}
@media (max-width: 767.98px) {
  .banner-section-two .banner-slider .content-box h1 {
    font-size: 42px;
    line-height: 50px;
    padding: 0 20px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-two .banner-slider .content-box h1 {
    font-size: 32px;
  }
}
.banner-section-two .banner-slider .content-box .btn {
  color: var(--theme-color-light);
  text-transform: uppercase;
  width: 170px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  padding: 0;
  border: 1px solid var(--theme-color-light);
  position: relative;
  z-index: 9;
}
.banner-section-two .banner-slider .content-box .btn:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.banner-section-two .banner-slider .content-box .btn:hover {
  border-color: var(--bg-theme-color1);
}
.banner-section-two .banner-slider .content-box .btn:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.checkout-form-section {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.checkout-form-section.v2 {
  position: static;
  background: #222222;
}
.checkout-form-section.v2 .checkout-form {
  background: transparent;
}
.checkout-form-section.v2 .checkout-form .checkout-field {
  border-color: rgba(255, 255, 255, 0.1);
}
.checkout-form-section.v2 .checkout-form .checkout-field > h6 {
  color: #fff;
}
.checkout-form-section .checkout-form {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 15px 15px 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkout-form-section .checkout-form .checkout-field {
  padding: 14px 25px 11px;
  border-right: 1px solid #f8f5f0;
}
.checkout-form-section .checkout-form .checkout-field.br-0 {
  border-right: 0;
}
.checkout-form-section .checkout-form .checkout-field > h6 {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--text-font);
  text-transform: uppercase;
  margin-bottom: -8px;
}
.checkout-form-section .checkout-form .checkout-field .chk-field {
  position: relative;
}
.checkout-form-section .checkout-form .checkout-field .chk-field input,
.checkout-form-section .checkout-form .checkout-field .chk-field select {
  font-size: 12px;
  color: #aa8453;
  width: 260px;
  background: transparent;
  border-color: transparent;
  z-index: 99;
  position: relative;
}
.checkout-form-section .checkout-form .checkout-field .chk-field i {
  position: absolute;
  top: 0;
  right: 0;
  color: #cab293;
  font-size: 18px;
}
.checkout-form-section .checkout-form .checkout-field .chk-field select option {
  font-size: 14px;
}
.checkout-form-section .checkout-form .btn-style-one {
  margin-left: auto;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 0px;
  line-height: 45px;
  background-color: var(--theme-color1);
  border-color: transparent;
  padding: 0;
  width: 140px;
  text-align: center;
  margin-top: -8px;
  position: relative;
  border: 0;
  top: 5px;
  height: 50px;
  font-weight: 600;
  color: #fff;
}
.checkout-form-section .checkout-form .btn-style-one:before {
  background: #000;
}

.chk-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.chk-field select::-ms-expand {
  display: none;
}

.chk-field input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aa8453;
}

.chk-field input ::-moz-placeholder {
  /* Firefox 19+ */
  color: #aa8453;
}

.chk-field input:-ms-input-placeholder {
  /* IE 10+ */
  color: #aa8453;
}

.chk-field input:-moz-placeholder {
  /* Firefox 18- */
  color: #cab293;
}

@media only screen and (max-width: 1410px) {
  .checkout-form-section .checkout-form .checkout-field .chk-field input,
  .checkout-form-section .checkout-form .checkout-field .chk-field select {
    width: 255px;
  }
}
@media only screen and (max-width: 1366px) {
  .banner-section-two .slick-dots {
    bottom: 40px;
  }
  .banner-section-two .banner-slider .content-box {
    min-width: 700px;
  }
  .banner-section-two .banner-slider {
    min-height: 800px;
  }
}
@media only screen and (max-width: 1200px) {
  .banner-section-two .banner-slider .content-box {
    margin-top: 50px;
  }
  .banner-section-two .banner-slider {
    min-height: unset;
  }
  .checkout-form-section .checkout-form .checkout-field .chk-field input,
  .checkout-form-section .checkout-form .checkout-field .chk-field select {
    width: 190px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-section .banner-slider {
    height: 800px;
  }
  .checkout-form-section .checkout-form .checkout-field .chk-field input,
  .checkout-form-section .checkout-form .checkout-field .chk-field select {
    width: 185px;
  }
  .checkout-form-section .checkout-form .btn-style-one {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .banner-section .content-box {
    min-width: 100%;
  }
  .checkout-form-section .checkout-form .btn-style-one {
    margin-left: auto;
  }
  .checkout-form-section.v2 .checkout-form .checkout-field {
    margin-bottom: 20px;
  }
  .banner-section-two .banner-slider .content-box {
    min-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .banner-section .content-box {
    margin-top: -80px;
  }
  .checkout-form-section .checkout-form .checkout-field .chk-field input,
  .checkout-form-section .checkout-form .checkout-field .chk-field select {
    width: 100%;
  }
  .checkout-form-section .checkout-form {
    display: block;
  }
  .checkout-form-section .checkout-form .checkout-field {
    border: 0;
  }
  .checkout-form-section .checkout-form .btn-style-one {
    width: 100%;
  }
  .checkout-form-section .checkout-form .checkout-field {
    padding: 13px 0 11px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-section .content-box h1 {
    font-size: 22px;
    line-height: 36px;
  }
  .banner-section-two .banner-slider .content-box h1 {
    font-size: 26px;
    line-height: 34px;
    padding: 0;
  }
}
/*** 

====================================================================
		About Section
====================================================================

***/
.exp-box {
  position: absolute;
  left: 135px;
  background: #000;
  width: 260px;
  text-align: center;
  padding: 36px 15px 33px;
  display: none
  ;
}
.exp-box figure {
  margin-bottom: 12px;
}
.exp-box .title {
  color: var(--theme-color-light);
  margin-bottom: 9px;
}
.exp-box .text {
  color: #ababab;
  font-family: var(--text-font2);
  line-height: 24px;
}

.about-section {
  position: relative;
  padding: 120px 0 23px;
}
.about-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 710px;
  height: 544px;
  /* background-image: url(../images/background/pattern.png); */
}
.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
.about-section .content-column .inner-column {
  position: relative;
  padding-left: 52px;
}
.about-section .content-column .sec-title {
  margin-bottom: 45px;
}
.about-section .content-column .sec-title .sub-title::before {
  display: none;
}
@media (max-width: 575.98px) {
  .about-section .content-column .sec-title h2 {
    font-size: 40px;
    line-height: 48px;
  }
}
.about-section .content-column .sec-title .text {
  margin-top: 35px;
}
.about-section .content-column .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.about-section .content-column .btn-box .theme-btn {
  margin-right: 42px;
  border: 0;
  background-color: #3c903a;
  color: var(--theme-color-light);
  text-transform: uppercase;
  padding: 17px 42px 18px;
}
.about-section .content-column .btn-box .theme-btn:before {
  background: #000;
}
.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}
@media (max-width: 575.98px) {
  .about-section .image-column {
    display: none;
  }
}
.about-section .image-column .inner-column {
  position: relative;
  padding-left: 20px;
  padding-bottom: 82px;
}
.about-section .image-column .inner-column .icon-dots {
  position: absolute;
  left: -120px;
  bottom: -30px;
}
.about-section .image-column .image-1 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 0px;
}
/* .about-section .image-column .image-1::after {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 30px;
  height: 250px;
  background-color:#3c903a;
} */
.about-section .image-column .image-1 img {
  width: 100%;
}
.about-section .image-column .image-2 {
  position: absolute;
  right: 18px;
  bottom: 41px;
  margin-bottom: 0;
  border: 15px solid var(--theme-color-light);
  overflow: hidden;
}
.about-section .image-column .image-5 {
  position: absolute;
  bottom: 30px;
  left: -70px;
  z-index: -1;
}
.about-section .image-column .image-4 {
  position: absolute;
  bottom: 65px;
  left: -97px;
  z-index: -1;
}
/* .about-section .image-column .btn-box {
  position: absolute;
  top: 79px;
  right: 25px;
  height: 133px;
  width: 133px;
  text-align: center;
  line-height: 133px;
} */
/* .about-section .image-column .btn-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #d4d4d5;
  height: 100%;
} */
@media (max-width: 991.98px) {
  .about-section .image-column .btn-box {
    right: 193px;
  }
}
/* .about-section .image-column .btn-box .play-now-two {
  width: 107px;
  height: 107px;
  line-height: 107px;
  text-align: center;
  background: #3c903a;
  color: var(--theme-color-light);
  font-size: 22px;
  display: inline-block;
  position: relative;
  z-index: 99;
} */
.about-section .outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-section .exp-box {
  top: 213px;
  left: 20%;
}

.info-block {
  position: relative;
  margin-bottom: 52px;
  margin-right: 115px;
}
.info-block:last-child {
  margin-right: 0;
}
.info-block .inner {
  position: relative;
  padding-left: 94px;
  min-height: 80px;
}
.info-block .inner:hover .icon-box {
  color: var(--theme-color-light);
}
.info-block .inner:hover .icon-box:before {
  opacity: 1;
}
.info-block .icon-box {
  position: absolute;
  left: 0;
  top: 3px;
}
.info-block .icon-box i {
  font-size: 64px;
  color: #000;
}

/*** 

====================================================================
		About Section
====================================================================

***/
.about-section-two {
  position: relative;
  padding: 120px 0 80px;
}
.about-section-two .info-block {
  margin-bottom: 24px;
}
@media (max-width: 575.98px) {
  .about-section-two {
    padding-top: 80px;
  }
}
.about-section-two .content-column {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
.about-section-two .content-column .inner-column {
  position: relative;
}
.about-section-two .content-column .inner-column .sec-title {
  margin-bottom: 38px;
}
.about-section-two .content-column .inner-column .sec-title .sub-title::before {
  display: none;
}
@media (max-width: 1199.98px) {
  .about-section-two .content-column .inner-column .sec-title h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
.about-section-two .content-column .inner-column .sec-title .text {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-color1);
}
.about-section-two .content-column .inner-column .list-style-two {
  margin-top: 0;
}
.about-section-two .content-column .inner-column .list-style-two li {
  padding-left: 27px;
}
.about-section-two .content-column .inner-column .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 45px;
}
.about-section-two .content-column .inner-column .btn-box .theme-btn {
  margin-right: 42px;
  border: 0;
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  text-transform: uppercase;
  padding: 17px 42px 18px;
}
.about-section-two .content-column .inner-column .btn-box .theme-btn:before {
  background: #000;
}
.about-section-two .content-column .inner-column .btn-box .theme-btn:hover {
  color: #fff;
}
.about-section-two
  .content-column
  .inner-column
  .btn-box
  .theme-btn:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.about-section-two .content-column .inner-column .btn-box .contact-info {
  position: relative;
  padding-left: 72px;
  top: -2px;
}
.about-section-two .content-column .inner-column .btn-box .contact-info .image {
  position: absolute;
  left: 0;
  top: 6px;
}
.about-section-two .content-column .inner-column .btn-box .contact-info span {
  text-transform: uppercase;
}
.about-section-two .content-column .inner-column .btn-box .contact-info .title {
  margin-bottom: 0;
  margin-top: -2px;
}
.about-section-two .image-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section-two .image-column .inner-column {
  position: relative;
  padding-left: 20px;
  padding-bottom: 117px;
  text-align: right;
}
@media (max-width: 991.98px) {
  .about-section-two .image-column .inner-column {
    text-align: center;
  }
}
.about-section-two .image-column .inner-column .icon-dots {
  position: absolute;
  left: -120px;
  bottom: -30px;
}
.about-section-two .image-column .image-1 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 0px;
}
.about-section-two .image-column .image-1::after {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 30px;
  height: 250px;
  background-color: var(--theme-color1);
}
.about-section-two .image-column .image-1 img {
  width: 100%;
}
.about-section-two .image-column .image-2 {
  position: absolute;
  left: -124px;
  bottom: 0;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .about-section-two .image-column .image-2 {
    left: 0;
  }
}
.about-section-two .outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575.98px) {
  .about-section-two .outer-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.about-section-two .exp-box {
  bottom: 18px;
}

.info-block {
  position: relative;
  margin-bottom: 49px;
  margin-right: 115px;
}
.info-block:last-child {
  margin-right: 0;
}
.info-block .inner {
  position: relative;
  padding-left: 94px;
  min-height: 80px;
}
.info-block .inner:hover .icon-box {
  color: var(--theme-color-light);
}
.info-block .inner:hover .icon-box:before {
  opacity: 1;
}
.info-block .image-box {
  position: absolute;
  left: 0;
  top: 3px;
}

.contact-info {
  position: relative;
  padding-left: 72px;
  top: -2px;
}
.contact-info .icon-box {
  position: absolute;
  left: 0;
  top: 6px;
}
.contact-info .icon-box i {
  color: var(--theme-color1);
  font-size: 48px;
}
.contact-info span {
  text-transform: uppercase;
}
.contact-info .title {
  margin-bottom: 0;
  margin-top: -2px;
}

@media only screen and (max-width: 1200px) {
  .info-block {
    margin-right: 30px;
  }
  .contact-info .title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .about-section .content-column .inner-column {
    padding-left: 0;
  }
  .info-block .title {
    max-width: 150px;
  }
  .about-section-two .image-column .exp-box {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 576px) {
  .info-block {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .about-section .outer-box {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .about-section .outer-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-section .content-column .btn-box {
    display: block;
  }
  .contact-info {
    margin-top: 40px;
  }
  .about-section-two .image-column .image-1:before {
    display: none;
  }
  .about-section-two .content-column .inner-column .sec-title h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
/*** 

====================================================================
  service-section
====================================================================

***/
.service-section {
  position: relative;
  padding: 120px 0 88px;
}
@media (max-width: 575.98px) {
  .service-section .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .service-section .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .service-section .sec-title h2 {
    font-size: 32px;
  }
}
.service-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 665px;
  height: 466px;
  background-image: url(https://kodesolution.com/html/2023/hoexr-html/images/background/home.png);
}

.service-block {
  margin-bottom: 24px;
}
.service-block .inner-box {
  position: relative;
  text-align: center;
  border: 2px solid #f8f5f0;
  padding: 45px 20px 33px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #fff;
}
.service-block .inner-box:hover {
  background-color: var(--theme-color-dark);
}
.service-block .inner-box:hover .content-box .title {
  color: var(--theme-color-light);
}
.service-block .inner-box:hover .content-box .text {
  color: var(--theme-color-light);
}
.service-block .inner-box:hover .icon-box {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.service-block .inner-box:hover .icon-box i {
  color: #fff;
}
.service-block .inner-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 95px;
  height: 11px;
  background-color: #3c903a ;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.service-block .inner-box .icon-box {
  margin-bottom: 11px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.service-block .inner-box .icon-box i {
  color: #ab8555;
  font-size: 58px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block .inner-box .content-box .title {
  margin-bottom: 18px;
}
.service-block .inner-box .content-box .text {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*** 

====================================================================
  service-section two
====================================================================

***/
.service-section-two {
  position: relative;
  padding: 120px 0 88px;
  background-color: #f8f5f0;
}
@media (max-width: 575.98px) {
  .service-section-two {
    padding: 80px 0 50px;
  }
}
@media (max-width: 575.98px) {
  .service-section-two .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .service-section-two .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .service-section-two .sec-title h2 {
    font-size: 29px;
  }
}
.service-section-two::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 665px;
  height: 466px;
  /* background-image: url(../images/background/home.png); */
}

.service-block-two {
  margin-bottom: 32px;
}
.service-block-two .inner-box-two {
  height: 100%;
}
.service-block-two .inner-box-two .image {
  overflow: hidden;
}
.service-block-two .inner-box-two .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service-block-two .inner-box-two:hover .image img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.service-block-two .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
  padding: 47px 40px 44px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1199.98px) {
  .service-block-two .inner-box {
    padding: 20px 28px 30px;
  }
}
.service-block-two .inner-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 95px;
  height: 11px;
  background-color: var(--theme-color1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.service-block-two .inner-box:hover {
  background-color: var(--theme-color-dark);
}
.service-block-two .inner-box:hover::before {
  opacity: 1;
  visibility: visible;
  left: 40px;
}
.service-block-two .inner-box:hover .image-box .icon-box i {
  color: #fff;
}
.service-block-two .inner-box:hover .image-box .title {
  color: var(--theme-color-light);
}
.service-block-two .inner-box:hover .image-box .image {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}
.service-block-two .inner-box:hover .image-box .image::before {
  background-color: var(--theme-color1);
}
.service-block-two .inner-box:hover .content-box .text {
  color: #adadad;
}
.service-block-two .inner-box .image-box {
  position: relative;
  padding-left: 93px;
  min-height: 58px;
  padding-top: 14px;
  margin-bottom: 22px;
}
@media (max-width: 1199.98px) {
  .service-block-two .inner-box .image-box {
    padding-left: 0;
  }
}
.service-block-two .inner-box .image-box .icon-box {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 99;
}
.service-block-two .inner-box .image-box .icon-box i {
  font-size: 64px;
  color: #aa8453;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1199.98px) {
  .service-block-two .inner-box .image-box .icon-box {
    position: static;
  }
}
.service-block-two .inner-box .image-box .icon-box::before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 30px;
  height: 30px;
  background-color: #f8f5f0;
  border-radius: 50%;
  z-index: -1;
}
.service-block-two .inner-box .image-box .title {
  margin-bottom: 18px;
}
.service-block-two .inner-box .content-box .text {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*** 

====================================================================
  testimonial-section
====================================================================

***/
.testimonial-section {
  position: relative;
  padding: 111px 0 90px;
}
@media (max-width: 575.98px) {
  .testimonial-section {
    padding: 80px 0;
  }
}

.testimonial-column .inner-column .sec-title {
  margin-bottom: 64px;
}
@media (max-width: 575.98px) {
  .testimonial-column .inner-column .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .testimonial-column .inner-column .sec-title .sub-title {
    margin-bottom: 0;
  }
}
.testimonial-column .inner-column .sec-title .sub-title::before {
  display: none;
}
.testimonial-column .inner-column .sec-title .sub-title:after {
  /* background-image: url(../images/icons/before-white.png); */
}
@media (max-width: 575.98px) {
  .testimonial-column .inner-column .sec-title h2 {
    font-size: 32px;
  }
}
.testimonial-column .inner-column .testimonial-slide .content-box {
  position: relative;
  padding-left: 193px;
}
@media (max-width: 1199.98px) {
  .testimonial-column .inner-column .testimonial-slide .content-box {
    padding-left: 170px;
  }
}
@media (max-width: 575.98px) {
  .testimonial-column .inner-column .testimonial-slide .content-box {
    padding-left: 0;
  }
}
.testimonial-column .inner-column .testimonial-slide .content-box .image-box {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 575.98px) {
  .testimonial-column .inner-column .testimonial-slide .content-box .image-box {
    position: static;
  }
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .content-box
  .image-box
  .thumb {
  position: relative;
  z-index: 99;
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .content-box
  .image-box
  .thumb::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -10px;
  width: 110px;
  height: 200px;
  background-color: #a98860;
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .content-box
  .image-box
  .thumb
  img {
  position: relative;
}
.testimonial-column .inner-column .testimonial-slide .info-box .rating-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}
.testimonial-column .inner-column .testimonial-slide .info-box .rating-list li {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 2px;
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .info-box
  .rating-list
  li:last-child {
  margin-right: 0;
}
.testimonial-column .inner-column .testimonial-slide .info-box .text {
  color: var(--theme-color-light);
  margin-bottom: 26px;
}
@media (max-width: 1199.98px) {
  .testimonial-column .inner-column .testimonial-slide .info-box .text {
    font-size: 19px;
  }
}
@media (max-width: 991.98px) {
  .testimonial-column .inner-column .testimonial-slide .info-box .text {
    font-size: 16px;
  }
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .info-box
  .auther-info
  .title {
  color: var(--theme-color-light);
  margin-bottom: -6px;
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .info-box
  .auther-info
  .designation {
  color: var(--theme-color-light);
}
.testimonial-column .inner-column .testimonial-slide .info-box .icon-box {
  position: absolute;
  right: 15px;
  bottom: 50px;
}
.testimonial-column
  .inner-column
  .testimonial-slide
  .info-box
  .icon-box
  .image
  img {
  width: unset;
}

.testimonial-slider .slick-dots {
  width: auto;
  bottom: -6px;
  right: 0;
  text-align: right;
}
.testimonial-slider .slick-dots li {
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  border-radius: 50%;
  border-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial-slider .slick-dots li.slick-active {
  border-color: #fff;
}
.testimonial-slider .slick-dots li button {
  width: 4px;
  height: 4px;
  background: #ffffff;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.testimonial-slider .slick-dots li button:before {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .hide-on-tablet {
    display: none;
  }
  .testimonial-column {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
/***

====================================================================
    team Section
====================================================================

***/
.team-section {
  position: relative;
  padding: 120px 0 80px;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .team-section {
    padding: 80px 0 30px;
  }
}
@media (max-width: 575.98px) {
  .team-section .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .team-section .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .team-section .sec-title h2 {
    font-size: 29px;
  }
}

.team-block {
  position: relative;
  margin-bottom: 40px;
}
.team-block .inner-box {
  position: relative;
}
.team-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.team-block .inner-box:hover .social-links {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.team-block .image-box {
  position: relative;
}
.team-block .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  z-index: 1;
}
.team-block .image-box .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .image-box .social-links {
  position: absolute;
  right: 14px;
  margin-top: 0;
  bottom: 116px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.team-block .image-box .social-links a {
  position: relative;
  font-size: 16px;
  color: var(--theme-color-light);
  display: inline-block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-top: 8px;
}
.team-block .image-box .social-links a:first-child {
  margin-top: 0;
  margin-left: 0;
}
.team-block .info-box {
  position: relative;
  padding: 21px 24px 20px;
  background-color: var(--theme-color-light);
  margin: 0 auto;
  border-radius: 0px 0px 10px 10px;
}
.team-block .info-box::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  width: 3px;
  height: 42px;
  background-color: var(--theme-color1);
}
.team-block .info-box::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 6px;
  width: 1px;
  height: 20px;
  background-color: var(--theme-color1);
}
.team-block .info-box .name {
  margin-bottom: 0px;
}
.team-block .info-box .name:hover {
  color: var(--theme-color1);
}
.team-block .info-box .designation {
  position: relative;
  display: block;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  margin-bottom: -2px;
  font-family: var(--text-font2);
}

/*** 

====================================================================
  video-section
====================================================================

***/
.video-section {
  position: relative;
  padding: 299px 0 126px;
}
@media (max-width: 575.98px) {
  .video-section {
    padding: 210px 0 80px;
  }
}
.video-section .content-column .inner-column .sec-title-two {
  position: relative;
}
.video-section .content-column .inner-column .sec-title-two .sub-title {
  position: relative;
  font-size: 18px;
  color: var(--text-color);
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--text-font2);
  letter-spacing: 7px;
  margin-bottom: 19px;
}
@media (max-width: 575.98px) {
  .video-section .content-column .inner-column .sec-title-two .sub-title {
    font-size: 15px;
  }
}
.video-section .content-column .inner-column .sec-title-two .sub-title::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 100%;
  width: 37px;
  height: 10px;
  margin-left: 8px;
  /* background-image: url(../images/icons/before.png); */
}
.video-section .content-column .inner-column .sec-title-two h2 {
  position: relative;
  display: block;
  font-size: 60px;
  color: var(--theme-color-light);
  font-family: var(--sec-title-font-family);
  font-weight: var(--sec-title-font-weight);
  margin-bottom: 0;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .video-section .content-column .inner-column .sec-title-two h2 {
    font-size: 53px;
  }
}
@media (max-width: 767.98px) {
  .video-section .content-column .inner-column .sec-title-two h2 {
    font-size: 41px;
  }
}
@media (max-width: 575.98px) {
  .video-section .content-column .inner-column .sec-title-two h2 {
    font-size: 27px;
    line-height: 40px;
  }
}
.video-section .btn-column .inner-column .play-now-two {
  position: absolute;
  top: 120px;
  font-size: 31px;
  color: var(--theme-color1);
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px solid var(--theme-color-light);
  border-radius: 50%;
  display: inline-block;
}
@media (max-width: 575.98px) {
  .video-section .btn-column .inner-column .play-now-two {
    width: 100px;
    height: 100px;
    line-height: 100px;
    top: 70px;
  }
}

/*** 

====================================================================
  room-section
====================================================================

***/
.rooms-section {
  position: relative;
  padding: 120px 0;
  background: #f8f5f0;
}
@media (max-width: 575.98px) {
  .rooms-section .sec-title {
    margin-bottom: 40px;
  }
}
@media (max-width: 575.98px) {
  .rooms-section .sec-title h2 {
    font-size: 32px;
  }
}

.room-block.col-lg-3 .inner-box .box-caption .bx-links li {
  margin-right: 3px;
}
.room-block .inner-box {
  position: relative;
}
.room-block .inner-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#222222),
    to(#222222)
  );
  background: linear-gradient(to left, #222222, #222222);
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.room-block .inner-box:hover .box-caption {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
.room-block .inner-box:hover .content-box {
  bottom: 84px;
  z-index: 9;
}
.room-block .inner-box:hover:before {
  opacity: 0.4;
  visibility: visible;
}
.room-block .inner-box:hover .image-box figure img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.room-block .inner-box .box-caption {
  position: absolute;
  left: 30px;
  width: calc(100% - 60px);
  z-index: 99;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  bottom: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.room-block .inner-box .box-caption .book-btn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 14pxx;
  color: #ffffff;
  font-family: var(--text-font2);
  letter-spacing: 2px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.room-block .inner-box .box-caption .book-btn:hover {
  background-color: var(--theme-color1);
  border-color: transparent;
}
.room-block .inner-box .box-caption .bx-links {
  margin-left: auto;
}
.room-block .inner-box .box-caption .bx-links li {
  display: inline-block;
  margin-right: 11px;
}
.room-block .inner-box .box-caption .bx-links li:last-child {
  margin-right: 0;
}
.room-block .inner-box .box-caption .bx-links li a {
  color: #fff;
  font-size: 16px;
}
.room-block .inner-box .image-box figure {
  margin-bottom: 27px;
  overflow: hidden;
  height: 40vh !important;
}
.room-block .inner-box .image-box figure img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.room-block .inner-box .content-box {
  position: absolute;
  bottom: 22px;
  right: 32px;
  text-align: right;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9;
}
.room-block .inner-box .content-box .title {
  font-size: 27px;
  margin-bottom: -6px;
  color: var(--theme-color-light);
}
.room-block .inner-box .content-box .price {
  font-size: 14px;
  color: var(--theme-color-light);
}
@media (max-width: 991.98px) {
  .room-block .inner-box .image-box .image-2 img {
    width: 100%;
    height: 340px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.room-facility-list {
  position: relative;
}
.room-facility-list .icon {
  border: 1px solid var(--theme-color1);
  border-radius: 50%;
  display: block;
  font-size: 22px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  width: 64px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.room-facility-list .list-one:hover .icon {
  background-color: var(--theme-color1);
  color: #fff;
}

.room-details__content-right {
  position: relative;
  display: block;
  margin-top: 40px;
}

.room-details__details-box {
  background-color: #f5f6f7;
  border: 1px solid #3c903a;
  border-radius: 10px;
  padding: 43px 50px 30px 50px;
  position: relative;
  z-index: 1;
}

.product-details.rd-page .bx-wrapper {
  margin-bottom: 30px;
}
.product-details.rd-page .bx-wrapper .thumb-box li {
  height: auto;
}

@media only screen and (max-width: 480px) {
  .room-block .inner-box .box-caption .book-btn {
    padding: 0 10px;
    margin-right: 10px;
  }
  .room-block.col-lg-3 .inner-box .box-caption .bx-links li {
    margin-right: 10px;
  }
  .rooms-section {
    padding: 80px 0;
  }
}
/*** 

====================================================================
  feature-section
====================================================================

***/
.feature-section {
  position: relative;
  padding: 120px 0 60px;
}
@media (max-width: 767.98px) {
  .feature-section {
    padding-top: 70px;
  }
}

.feature-row:nth-child(even) .image-column .inner-column {
  margin-right: 0;
  margin-left: -170px;
}
.feature-row:nth-child(even) .content-column .inner-column {
  padding: 61px 231px 70px 60px;
}
.feature-row:hover .image-column .inner-column .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.feature-row .image-column .inner-column {
  margin-right: -170px;
  position: relative;
  z-index: 9;
}
.feature-row .content-column {
  margin-bottom: 60px;
}
.feature-row .content-column .inner-column {
  position: relative;
  background-color: #f8f5f0;
  padding: 61px 0 70px 231px;
  /* margin-top: 50px; */
}
@media (max-width: 1199.98px) {
  .feature-row .content-column .inner-column {
    padding: 61px 0 70px 203px;
  }
}
@media (max-width: 991.98px) {
  .feature-row .content-column .inner-column {
    padding-left: 40px;
    padding-right: 0;
  }
}
@media (max-width: 575.98px) {
  .feature-row .content-column .inner-column {
    padding: 61px 40px 70px 40px;
    margin-top: 0;
  }
}
.feature-row .content-column .inner-column::before {
  content: "";
  position: absolute;
  top: 0;
  left: 232px;
  width: 95px;
  height: 11px;
  background-color:#3c903a;
}
.feature-row .content-column .inner-column .sec-title {
  margin-bottom: 34px;
}
.feature-row .content-column .inner-column .sec-title .text {
  margin-top: 31px;
}
.feature-row .content-column .inner-column .sec-title .sub-title {
  margin-bottom: 11px;
}
.feature-row .content-column .inner-column .sec-title .sub-title::before {
  display: none;
}
@media (max-width: 1199.98px) {
  .feature-row .content-column .inner-column .sec-title h2 {
    font-size: 40px;
  }
}
@media (max-width: 1199.98px) {
  .feature-row .content-column .inner-column .sec-title .text {
    font-size: 16px;
  }
}
.feature-row .content-column .inner-column .image-2 {
  position: absolute;
  top: 18px;
  right: 13px;
}
.feature-row .image-column .image {
  overflow: hidden;
}
.feature-row .image-column .image img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1200px) {
  .feature-row:nth-child(even) .content-column .inner-column .sec-title {
    max-width: 70%;
  }
}
@media only screen and (max-width: 991px) {
  .feature-row .content-column .inner-column {
    margin-top: 0;
  }
  .feature-row .content-column .inner-column::before {
    left: 40px;
  }
  .feature-row .image-column .inner-column {
    margin-right: 0;
  }
  .feature-row:nth-child(even) .image-column .inner-column {
    margin: 0;
  }
  .feature-row:nth-child(even) .content-column {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .feature-row:nth-child(2n) .content-column .inner-column .sec-title {
    max-width: 100%;
  }
  .feature-row:nth-child(2n) .content-column .inner-column {
    padding: 61px 40px 70px 40px;
  }
}
@media only screen and (max-width: 576px) {
  .feature-row .image-column .inner-column {
    margin-bottom: 20px;
  }
  .feature-row:nth-child(even) .content-column .inner-column .sec-title {
    max-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .feature-row:nth-child(2n) .content-column .inner-column {
    padding: 61px 40px 70px 40px;
  }
}
/*** 

====================================================================
  call-section
====================================================================

***/
.call-to-action {
  position: relative;
  padding: 120px 0;
}
.call-to-action .booking-form-column .inner-column {
  margin: 0;
}
.call-to-action .booking-form-column .inner-column:after {
  left: auto;
  top: auto;
  bottom: -120px;
  right: -75px;
}
.call-to-action .content-colum .inner-column .sec-title {
  margin-bottom: 54px;
}
.call-to-action .content-colum .inner-column .sec-title .sub-title::before {
  display: none;
}
@media (max-width: 575.98px) {
  .call-to-action .content-colum .inner-column .sec-title h2 {
    font-size: 37px;
  }
}
.call-to-action .content-colum .inner-column .call-block {
  margin-bottom: 42px;
}
.call-to-action .content-colum .inner-column .call-block .inner-box {
  padding-left: 110px;
  position: relative;
}
.call-to-action .content-colum .inner-column .call-block .inner-box .icon-box {
  position: absolute;
  left: 0;
  width: 84px;
  height: 84px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.call-to-action
  .content-colum
  .inner-column
  .call-block
  .inner-box
  .icon-box
  i {
  color: #aa8453;
  font-size: 48px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.call-to-action .content-colum .inner-column .call-block .inner-box .title {
  margin-bottom: 13px;
}
@media (max-width: 575.98px) {
  .call-to-action .content-colum .inner-column .call-block .inner-box .title {
    font-size: 20px;
  }
}
@media (max-width: 1199.98px) {
  .call-to-action .content-colum .inner-column .call-block .inner-box .text {
    font-size: 15px;
  }
}
@media (max-width: 575.98px) {
  .call-to-action .content-colum .inner-column .call-block .inner-box .text {
    font-size: 17px;
  }
}
.call-to-action
  .content-colum
  .inner-column
  .call-block
  .inner-box:hover
  .icon-box {
  background-color:#388d3b;
}
.call-to-action
  .content-colum
  .inner-column
  .call-block
  .inner-box:hover
  .icon-box
  i {
  color: #fff;
}
.call-to-action .content-colum .inner-column .btn-box .read-more {
  margin-top: 4px;
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
  width: 170px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--text-font2);
  position: relative;
  z-index: 9;
}
.call-to-action .content-colum .inner-column .btn-box .read-more:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.call-to-action .content-colum .inner-column .btn-box .read-more:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.call-to-action .content-colum .inner-column .btn-box .read-more:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .call-to-action .content-colum {
    margin-bottom: 50px;
  }
}
/*** 

====================================================================
    News Section
====================================================================

***/
.news-section {
  position: relative;
  padding: 120px 0 90px;
}
.news-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 713px;
  height: 544px;
  /* background-image: url(../images/icons/bg-shape.png); */
  background-repeat: no-repeat;
}
@media (max-width: 575.98px) {
  .news-section .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .news-section .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .news-section .sec-title h2 {
    font-size: 29px;
  }
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}
.news-block .inner-box {
  position: relative;
}
.news-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box:hover .image-box .date {
  bottom: 40px;
}
.news-block .inner-box .image-box {
  position: relative;
}
.news-block .inner-box .image-box .image {
  overflow: hidden;
}
.news-block .inner-box .image-box .image img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box .image-box .date {
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 14px;
  height: 85px;
  width: 45px;
  background-color: var(--theme-color-dark);
  color: var(--theme-color-light);
  display: inline-block;
  text-align: center;
  padding-top: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block .inner-box .image-box .date small {
  font-size: 24px;
  margin-top: -4px;
  display: inline-block;
}
.news-block .inner-box .content-box {
  position: relative;
  background-color: #fff;
  padding: 21px 0px 21px 30px;
  margin-left: 60px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -100px;
}
@media (max-width: 575.98px) {
  .news-block .inner-box .content-box {
    padding: 21px 0px 21px 20px;
  }
}
.news-block .inner-box .content-box .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 3px;
}
.news-block .inner-box .content-box .post-info li {
  position: relative;
  font-size: 12px;
  color: #666666;
  margin-right: 17px;
}
.news-block .inner-box .content-box .post-info li:last-child {
  margin-right: 0;
}
.news-block .inner-box .content-box .post-info li i {
  color: var(--theme-color1);
  margin-right: 7px;
}
.news-block .inner-box .content-box .title {
  color: var(--theme-color-dark);
  margin-bottom: 12px;
}
@media (max-width: 575.98px) {
  .news-block .inner-box .content-box .title {
    font-size: 22px;
  }
}
.news-block .inner-box .content-box .title:hover a {
  color: var(--theme-color1);
}
.news-block .inner-box .content-box .read-more {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: var(--theme-color-dark);
  background-color: #f8f5f0;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: var(--text-font2);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
.news-block .inner-box .content-box .read-more:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  z-index: -1;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.news-block .inner-box .content-box .read-more:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.news-block .inner-box .content-box .read-more i {
  position: relative;
  top: 0px;
  margin-left: 10px;
}
.news-block .inner-box .content-box .read-more:hover {
  color: #fff;
}
.news-block .inner-box .content-box .read-more:hover i {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*** 

====================================================================
    News Section two
====================================================================

***/
.news-section-two {
  position: relative;
  padding: 120px 0 90px;
}
.news-section-two::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 712px;
  height: 544px;
  /* background-image: url(../images/resource/pattern-4.png); */
}
@media (max-width: 575.98px) {
  .news-section-two .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .news-section-two .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .news-section-two .sec-title h2 {
    font-size: 29px;
  }
}

.news-block-two {
  position: relative;
  margin-bottom: 30px;
}
.news-block-two .inner-box {
  position: relative;
}
.news-block-two .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .inner-box .image-box {
  position: relative;
}
.news-block-two .inner-box .image-box .image {
  overflow: hidden;
}
.news-block-two .inner-box .image-box .image img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .inner-box .image-box .date {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 14px;
  height: 85px;
  width: 45px;
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  display: inline-block;
  text-align: center;
  padding-top: 14px;
}
.news-block-two .inner-box .image-box .date small {
  font-size: 24px;
  display: block;
}
.news-block-two .inner-box .content-box {
  position: relative;
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 0 15px 60px rgba(68, 67, 67, 0.08);
  box-shadow: 0 15px 60px rgba(68, 67, 67, 0.08);
  padding: 20px 0px 0 34px;
  margin: 0 25px;
  margin-top: -100px;
}
@media (max-width: 575.98px) {
  .news-block-two .inner-box .content-box {
    padding: 21px 0px 0 20px;
  }
}
.news-block-two .inner-box .content-box .info-box {
  padding-left: 82px;
  margin-bottom: 16px;
}
.news-block-two .inner-box .content-box .info-box .thumb {
  position: absolute;
  left: 30px;
  top: -25px;
  display: inline-block;
  border: 2px solid var(--theme-color1);
}
.news-block-two .inner-box .content-box .info-box .designation {
  font-size: 14px;
}
.news-block-two .inner-box .content-box .title {
  color: var(--theme-color-dark);
  margin-bottom: 18px;
}
@media (max-width: 1199.98px) {
  .news-block-two .inner-box .content-box .title {
    font-size: 20px;
  }
}
.news-block-two .inner-box .content-box .title:hover a {
  color: var(--theme-color1);
}
.news-block-two .inner-box .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #f8f5f0;
  margin-left: -34px;
  padding-left: 34px;
}
.news-block-two .inner-box .content-box .btn-box .read-more {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  color: var(--theme-color-dark);
  background-color: #f8f5f0;
  padding: 8px 20px 9px 20px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: var(--text-font2);
  letter-spacing: 1.5px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  transition: all 0.3s ease;
}
.news-block-two .inner-box .content-box .btn-box .read-more:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.news-block-two .inner-box .content-box .btn-box .read-more:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.news-block-two .inner-box .content-box .btn-box .read-more i {
  position: relative;
  top: 0px;
  margin-left: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .inner-box .content-box .btn-box .read-more:hover {
  color: #fff;
}
.news-block-two .inner-box .content-box .btn-box .read-more:hover i {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.news-block-two .inner-box .content-box .btn-box span {
  font-size: 12px;
  color: var(--text-color);
}
.news-block-two .inner-box .content-box .btn-box span .icon {
  color: var(--theme-color1);
  margin-right: 7px;
}

@media only screen and (max-width: 480px) {
  .news-block .inner-box .content-box .title {
    font-size: 20px;
    padding-right: 10px;
  }
}
/*** 

====================================================================
		FAQ's Sectiom
====================================================================

***/
.faqs-section-home1 {
  position: relative;
  margin-top: -160px;
  padding: 260px 0 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.faqs-section-home1:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #ebf1f5;
  opacity: 0.95;
  content: "";
}

.faqs-section-home1 .sec-title {
  margin-bottom: 30px;
}

.faqs-section-home1 .faq-column {
  position: relative;
  margin-bottom: 50px;
}
.faqs-section-home1 .faq-column .inner-column {
  position: relative;
}

.faqs-section-home2 {
  position: relative;
  margin-top: -100px;
  padding: 200px 0 70px;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.accordion-box.style-two {
  position: relative;
  background-color: #ffffff;
  padding: 0 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.accordion-box.style-two .block {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
}
.accordion-box.style-two .block .acc-btn {
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 25px;
  padding: 25px 0;
  padding-right: 70px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box.style-two .block .acc-btn .icon {
  position: absolute;
  right: 0px;
  top: 40px;
  height: 35px;
  font-size: 14px;
  line-height: 38px;
  color: var(--headings-color);
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box.style-two .block .acc-btn.active {
  color: var(--theme-color1);
  background-color: transparent;
}
.accordion-box.style-two .block .acc-btn.active .icon {
  color: var(--bg-theme-color1);
}
.accordion-box.style-two .block .acc-btn.active .icon:before {
  content: "\f068";
}

.accordion-box.style-two .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box.style-two .block .acc-content .content {
  border-bottom: 1px solid #dddddd;
  padding: 25px 0;
  position: relative;
}
.accordion-box.style-two .block .acc-content .content .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #7c858c;
  margin-bottom: 0;
}
.accordion-box.style-two .block .acc-content.current {
  display: block;
}

.faq-block {
  position: relative;
  margin-bottom: 40px;
}
.faq-block .inner-box {
  padding-left: 70px;
  position: relative;
}
.faq-block .inner-box:hover .icon {
  background-color: var(--bg-theme-color1);
}
.faq-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  color: #fff;
  background-color: var(--bg-theme-color1);
  border-radius: 50%;
  font-size: 14px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.faq-block h4 {
  font-size: 18px;
  line-height: 1.2em;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 10px;
}
.faq-block .text {
  font-size: 16px;
  line-height: 30px;
}

.pie-graph {
  position: relative;
}
.pie-graph .graph-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
}
.pie-graph .graph-outer .inner-text {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 24px;
  color: var(--theme-color2);
  font-weight: 400;
  line-height: 1em;
}
.pie-graph .title {
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 0;
}

.home-2-tabs {
  position: relative;
}
.home-2-tabs .nav-tabs {
  border-bottom: none;
}
.home-2-tabs .nav-tabs .nav-link {
  background-color: #fff;
  border-color: transparent;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 72px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 1px 72px 0px rgba(0, 0, 0, 0.14);
  color: #151515;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 35px;
}
@media (max-width: 425px) {
  .home-2-tabs .nav-tabs .nav-link {
    margin-bottom: 20px;
  }
}
.home-2-tabs .nav-tabs .nav-link.active {
  background-color: #151515;
  color: #fff;
}

/***

==================================================================
	pricing section 
==================================================================

***/
.pricing-section {
  position: relative;
  padding: 120px 0 95px;
  background-color: var(--theme-color3);
}
@media (max-width: 575.98px) {
  .pricing-section {
    padding: 80px 0 70px;
  }
}
@media (max-width: 575.98px) {
  .pricing-section .sec-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .pricing-section .sec-title .sub-title {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .pricing-section .sec-title h2 {
    font-size: 35px;
  }
}

.pricing-block {
  margin-bottom: 13px;
}
.pricing-block .inner-box {
  position: relative;
  padding: 10px 0 13px 99px;
}
.pricing-block .inner-box:hover .thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.pricing-block .inner-box .food-pack {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 0;
  border: 1px solid #aa8453;
  padding: 0 13px;
  height: 24px;
  line-height: 20px;
  color: #aa8453;
  font-size: 12px;
  font-family: var(--text-font2);
  letter-spacing: 1px;
  z-index: 9;
  text-transform: uppercase;
}
.pricing-block .inner-box .thumb {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  max-width: 80px;
}
.pricing-block .inner-box .thumb img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pricing-block .inner-box .content-box .title {
  font-size: 20px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.pricing-block .inner-box .content-box .title span {
  color: #222222;
  position: relative;
}
.pricing-block .inner-box .content-box .title span::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -269px;
  width: 259px;
  height: 1px;
  border: 1px dashed #c0bfbe;
}
@media (max-width: 1199.98px) {
  .pricing-block .inner-box .content-box .title span::before {
    left: -197px;
    width: 190px;
  }
}
@media (max-width: 991.98px) {
  .pricing-block .inner-box .content-box .title span::before {
    display: none;
  }
}
.pricing-block .inner-box .content-box .designation {
  font-size: 14px;
  margin-bottom: 7px;
  display: inline-block;
}

/***

==================================================================
	pricing section two
==================================================================

***/
.pricing-section-two {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767.98px) {
  .pricing-section-two {
    padding: 80px 0 40px;
  }
}
@media (max-width: 575.98px) {
  .pricing-section-two .sec-title h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 767.98px) {
  .pricing-block-two {
    margin-bottom: 40px;
  }
}
.pricing-block-two .list-style-two {
  margin-top: 30px;
}
.pricing-block-two .list-style-two li {
  margin-bottom: 10px;
}
.pricing-block-two .inner-box {
  position: relative;
}
.pricing-block-two .inner-box .content-column .inner-column {
  position: relative;
  background-color: #f8f5f0;
  padding: 36px 40px 40px;
  margin-right: -75px;
  z-index: 99;
  margin-top: 34px;
}
@media (max-width: 1199.98px) {
  .pricing-block-two .inner-box .content-column .inner-column {
    margin-right: -113px;
  }
}
@media (max-width: 991.98px) {
  .pricing-block-two .inner-box .content-column .inner-column {
    margin-top: 0;
  }
}
.pricing-block-two .inner-box .content-column .inner-column::before {
  content: "";
  position: absolute;
  top: 0;
  left: 41px;
  width: 95px;
  height: 13px;
  background-color: var(--theme-color1);
}
.pricing-block-two .inner-box .content-column .inner-column .title {
  font-size: 48px;
  color: var(--theme-color1);
  margin-bottom: -14px;
}
.pricing-block-two .inner-box .content-column .inner-column .title span {
  font-size: 14px;
  color: var(--theme-color-dark);
}
.pricing-block-two .inner-box .content-column .inner-column span {
  font-size: 27px;
  color: var(--theme-color-dark);
  font-family: var(--title-font);
}
.pricing-block-two .inner-box .content-column .inner-column .book-now {
  font-size: 12px;
  padding: 0 30px;
  background-color: var(--theme-color-dark);
  height: 40px;
  color: var(--theme-color-light);
  display: inline-block;
  -webkit-font-feature-settings: var(--text-font2);
  font-feature-settings: var(--text-font2);
  line-height: 40px;
  text-align: center;
  margin-top: 22px;
  text-transform: uppercase;
  position: relative;
  z-index: 9;
}
.pricing-block-two .inner-box .content-column .inner-column .book-now:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.pricing-block-two
  .inner-box
  .content-column
  .inner-column
  .book-now:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.pricing-block-two .inner-box .content-column .inner-column .book-now:hover {
  color: #fff;
}
.pricing-block-two .inner-box .image-column .image-box {
  height: 100%;
}
.pricing-block-two .inner-box .image-column .image-box figure {
  height: 100%;
  position: relative;
}
.pricing-block-two .inner-box .image-column .image-box figure:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.pricing-block-two .inner-box .image-column .image-box figure:hover:before {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.pricing-block-two .inner-box .image-column .image-box figure img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .pricing-block-two .inner-box .image-column .image-box {
    display: none;
  }
  .pricing-block-two .inner-box .content-column .inner-column {
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .pricing-block .inner-box .food-pack {
    margin-top: 20px;
    position: static;
  }
  .pricing-block .inner-box {
    padding-top: 0;
  }
}
/*** 

====================================================================
  client-section
====================================================================

***/
.client-section {
  position: relative;
  padding: 120px 0 200px;
}
@media (max-width: 575.98px) {
  .client-section {
    padding: 80px 0 190px;
  }
}
@media (max-width: 575.98px) {
  .client-section .sec-title {
    margin-bottom: 40px;
  }
}
.client-section .sec-title .sub-title {
  color: #a6a5a6;
}
@media (max-width: 575.98px) {
  .client-section .sec-title .sub-title {
    font-size: 14px;
  }
}
@media (max-width: 575.98px) {
  .client-section .sec-title h2 {
    font-size: 32px;
  }
}

.client-block .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
  text-align: center;
  padding: 45px 15px 43px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 40px;
}
.client-block .inner-box:hover::before {
  opacity: 1;
  visibility: visible;
  left: 50%;
}
.client-block .inner-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 95px;
  height: 13px;
  background-color: #388d3b;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.client-block .inner-box .title {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .client-block .inner-box .text {
    font-size: 14px;
  }
}
.client-block .inner-box span {
  text-transform: uppercase;
  color:#388d3b;
  font-family: var(--text-font2);
}
.client-block .inner-box .image-box {
  position: relative;
  margin-bottom: 40px;
  margin-top: 21px;
}
.client-block .inner-box .image-box .icon {
  position: absolute;
  bottom: -13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 40px;
  color: var(--theme-color1);
}

/*** 

====================================================================
  offer-section
====================================================================

***/
.offer-banner {
  position: relative;
  margin-top: -120px;
}
.offer-banner .outer-box {
  position: relative;
  background-color: #f8f5f0;
  padding: 74px 103px 78px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .offer-banner .outer-box {
    padding: 44px 46px 50px;
  }
}
.offer-banner .outer-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 658px;
  height: 502px;
  /* background-image: url(../images/resource/pattern3.png); */
}
@media (max-width: 575.98px) {
  .offer-banner
    .outer-box
    .content-colum
    .inner-column
    .content-box
    .sub-title {
    margin-bottom: 20px;
    display: inline-block;
  }
}
.offer-banner .outer-box .content-colum .inner-column .content-box .title {
  font-size: 48px;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .offer-banner .outer-box .content-colum .inner-column .content-box .title {
    font-size: 39px;
  }
}
@media (max-width: 575.98px) {
  .offer-banner .outer-box .content-colum .inner-column .content-box .title {
    font-size: 30px;
    line-height: 40px;
    margin-top: 10px;
  }
}
.offer-banner .outer-box .btn-column .inner-column {
  text-align: right;
  margin-top: 21px;
}
@media (max-width: 991.98px) {
  .offer-banner .outer-box .btn-column .inner-column {
    text-align: center;
  }
}
.offer-banner .outer-box .btn-column .inner-column .read-more {
  padding: 0 47px;
  height: 60px;
  line-height: 60px;
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--text-font2);
  position: relative;
  z-index: 9;
}
.offer-banner .outer-box .btn-column .inner-column .read-more:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.offer-banner .outer-box .btn-column .inner-column .read-more:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.offer-banner .outer-box .btn-column .inner-column .read-more:hover {
  background: #000;
}

/*** 

====================================================================
  client-section two
====================================================================

***/
.client-section-two {
  position: relative;
  padding: 80px 0;
}
.client-section-two .client-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.client-section-two .client-slider .client-block-two:last-child {
  margin-right: 0;
}
.client-section-two .client-slider .client-block-two .inner-box .image img {
  width: unset;
  margin: 0 auto;
}

@media only screen and (max-width: 991px) {
  .offer-banner .content-colum {
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .client-block-two a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
/*** 

====================================================================
  food-section
====================================================================

***/
.food-section {
  position: relative;
  padding: 120px 0 60px;
}
@media (max-width: 575.98px) {
  .food-section {
    padding-bottom: 30px;
  }
}
@media (max-width: 991.98px) {
  .food-section .image-column {
    display: none;
  }
}
.food-section .image-column .inner-column {
  position: relative;
  margin-left: -375px;
}
@media (max-width: 1199.98px) {
  .food-section .image-column .inner-column {
    margin-right: -93px;
  }
}
.food-section .image-column .inner-column .play-now-two {
  position: absolute;
  top: 123px;
  right: 125px;
  font-size: 31px;
  color: var(--theme-color-light);
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px solid var(--theme-color-light);
  border-radius: 50%;
  display: inline-block;
}
@media (max-width: 1199.98px) {
  .food-section .image-column .inner-column .play-now-two {
    width: 100px;
    height: 100px;
    top: 40px;
    right: 40px;
    line-height: 100px;
  }
}
.food-section .image-column .inner-column .quote-box {
  position: absolute;
  bottom: -15px;
  right: -60px;
  max-width: 460px;
  background-color: #388d3b;
  padding: 37px 40px 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1399.98px) {
  .food-section .image-column .inner-column .quote-box {
    display: none;
  }
}
.food-section .image-column .inner-column .quote-box .icon-box {
  line-height: 83px;
}
.food-section .image-column .inner-column .quote-box .icon-box i {
  color: #000;
  font-size: 72px;
  position: relative;
  top: 15px;
}
.food-section .image-column .inner-column .quote-box .text {
  max-width: calc(100% - 100px);
  padding-left: 33px;
  font-size: 24px;
  color: var(--theme-color-light);
  line-height: 36px;
  font-family: var(--text-font2);
}
.food-section .content-column .inner-column {
  margin-top: 58px;
  margin-left: 116px;
}
@media (max-width: 1199.98px) {
  .food-section .content-column .inner-column {
    margin-top: 19px;
  }
}
@media (max-width: 1199.98px) {
  .food-section .content-column .inner-column {
    margin-left: 0;
  }
}
.food-section .content-column .inner-column .sec-title .sub-title::before {
  display: none;
}
@media (max-width: 575.98px) {
  .food-section .content-column .inner-column .sec-title h2 {
    font-size: 30px;
    line-height: 38px;
  }
}
.food-section .content-column .inner-column .sec-title .text {
  margin-top: 46px;
  margin-bottom: 37px;
}
@media (max-width: 767.98px) {
  .food-section .content-column .inner-column .sec-title .text {
    font-size: 14px;
  }
}
@media (max-width: 575.98px) {
  .food-section .content-column .inner-column .sec-title .text {
    font-size: 17px;
    margin-top: 29px;
  }
}
@media (max-width: 575.98px) {
  .food-section .content-column .inner-column .food-list.two {
    display: none;
  }
}
.food-section .content-column .inner-column .healthy-food {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.food-section .content-column .inner-column .healthy-food .food-list {
  margin-right: 100px;
}
@media (max-width: 575.98px) {
  .food-section .content-column .inner-column .healthy-food .food-list {
    margin-right: 0;
  }
}
.food-section
  .content-column
  .inner-column
  .healthy-food
  .food-list:last-child {
  margin-right: 0;
}
.food-section .content-column .inner-column .healthy-food .food-list ul li {
  position: relative;
  padding-left: 32px;
  margin-bottom: 8px;
}
.food-section
  .content-column
  .inner-column
  .healthy-food
  .food-list
  ul
  li:last-child {
  margin-bottom: 0;
}
.food-section .content-column .inner-column .healthy-food .food-list ul li i {
  position: absolute;
  left: 0;
  color: var(--theme-color1);
}
.food-section .content-column .inner-column .image-box {
  margin-top: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.food-section .content-column .inner-column .image-box .image {
  margin-right: 30px;
}
.food-section .content-column .inner-column .image-box .image:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1200px) {
  .food-section .image-column {
    display: none;
  }
}
/*** 

====================================================================
  BOOKING SECTION
====================================================================

***/
.booking-section {
  position: relative;
  padding: 110px 0 107px;
  margin-top: 87px;
}
.booking-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  background-image: url(https://kodesolution.com/html/2023/hoexr-html/images/background/bg-booking.jpg);
  background-repeat: no-repeat;
}

.booking-content-column .inner-column {
  padding-left: 100px;
  position: relative;
}
.booking-content-column .inner-column .sec-title {
  margin-bottom: 54px;
}
.booking-content-column .inner-column p {
  color: #adadad;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 29px;
}
.booking-content-column .inner-column p:last-child {
  margin-bottom: 0;
}
.booking-content-column .inner-column .contact-info {
  margin-top: 45px;
}
.booking-content-column .inner-column .contact-info span,
.booking-content-column .inner-column .contact-info .title {
  color: #fff;
}

.booking-form-column .inner-column {
  padding: 35px 70px 70px;
  position: relative;
  z-index: 9;
  margin-top: -111px;
  padding: relative;
}
.booking-form-column .inner-column:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f5f0;
  z-index: 9;
  height: 100%;
}
.booking-form-column .inner-column:after {
  content: "";
  position: absolute;
  top: 87px;
  left: -80px;
  width: 424px;
  height: 688px;
  /* background-image: url(../images/bk-shape.png); */
  background-repeat: no-repeat;
}
.booking-form-column .inner-column .sec-title {
  margin-bottom: 54px;
  position: relative;
  z-index: 9;
}
.booking-form-column .inner-column .sec-title:before {
  content: "";
  position: absolute;
  top: -60px;
  left: 0;
  width: 95px;
  height: 13px;
  background: #388d3b;
}
.booking-form-column .inner-column .sec-title .sub-title:before {
  display: none;
}
.booking-form-column .inner-column .bk-form {
  position: relative;
  z-index: 9;
}
.booking-form-column .inner-column .bk-form .frm-field {
  position: relative;
  margin-bottom: 15px;
}
.booking-form-column .inner-column .bk-form .frm-field:last-child {
  margin-bottom: 0;
}
.booking-form-column .inner-column .bk-form .frm-field input,
.booking-form-column .inner-column .bk-form .frm-field select {
  width: 100%;
  height: 70px;
  color: #222222;
  font-size: 16px;
  padding: 0 30px;
  border: 1px solid #adadad;
  background: #fff;
}
.booking-form-column .inner-column .bk-form .frm-field img,
.booking-form-column .inner-column .bk-form .frm-field i {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #aa8453;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-select: none;
  pointer-events: none;
}
.booking-form-column .inner-column .bk-form .form-submit {
  margin-top: 23px;
}
.booking-form-column .inner-column .bk-form .form-submit button {
  background-color: #222222;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--text-font2);
  letter-spacing: 2px;
  width: 100%;
  height: 70px;
  line-height: 70px;
  border: 0;
  position: relative;
  z-index: 9;
}
.booking-form-column .inner-column .bk-form .form-submit button:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: var(--bg-theme-color1);
  border-radius: inherit;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  z-index: -1;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.booking-form-column .inner-column .bk-form .form-submit button:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.bk-form select::-ms-expand {
  display: none;
}

.bk-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@media only screen and (max-width: 1366px) {
  .booking-form-column .inner-column::after {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .booking-form-column .inner-column {
    margin-top: 0;
  }
  .booking-section {
    /* background: url(../images/background/bg-booking.jpg); */
    margin-top: 0;
  }
  .booking-section:before {
    display: none;
  }
  .booking-content-column .inner-column {
    padding-left: 0;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 576px) {
  .booking-form-column .inner-column {
    padding: 50px 25px;
  }
}
@media only screen and (max-width: 480px) {
  .booking-form-column .inner-column {
    padding: 60px 30px 70px;
  }
}
/***

==================================================================
	Main Footer
==================================================================

***/
.main-footer {
  position: relative;
  background-color: var(--bg-theme-color2);
  /* Widget Section */
}
.main-footer .bg {
  background-size: auto;
}
/* .main-footer .footer-bg-1 {
  background-image: url(../images/icons/footer-bg-1.png);
  background-color: #0a0a0a;
  background-position: left bottom;
}
.main-footer .footer-bg-2 {
  background-image: url(../images/icons/footer-bg-2.png);
  background-position: right bottom;
}
.main-footer .footer-bg-3 {
  background-image: url(../images/icons/footer-bg-3.png);
  background-position: right bottom;
}
.main-footer .footer-bg-4 {
  background-image: url(../images/icons/footer-bg-4.png);
  background-position: right bottom;
  opacity: 0.1;
}
.main-footer .footer-bg-5 {
  background-image: url(../images/icons/footer-bg-5.png);
  background-position: center;
  background-size: cover;
}
.main-footer .footer-bg-6 {
  background-image: url(../images/icons/footer-bg-6.png);
  background-position: right bottom;
}
.main-footer .footer-bg-7 {
  background-image: url(../images/icons/footer-bg-7.png);
}
.main-footer .footer-bg-8 {
  background-image: url(../images/icons/footer-bg-8.png);
}
.main-footer .footer-bg-9 {
  background-image: url(../images/icons/footer-bg-9.png);
  background-position: right bottom;
}
.main-footer .footer-bg-10 {
  background-image: url(../images/icons/footer-bg-10.png);
  background-position: right bottom;
}
.main-footer .footer-bg-11 {
  background-image: url(../images/icons/footer-bg-11.png);
} */
.main-footer .widgets-section {
  position: relative;
  padding: 116px 0 30px;
}
.main-footer .footer-column {
  position: relative;
  margin-bottom: 50px;
}
.main-footer .footer-widget {
  position: relative;
}
.main-footer .widget-title {
  position: relative;
  color: var(--theme-color-light);
  margin-bottom: 22px;
}
.main-footer .widget-content {
  position: relative;
}
.main-footer .widget-content .text {
  color: var(--theme-color-light);
}

.user-links {
  position: relative;
}
.user-links li {
  position: relative;
  font-size: 14px;
  line-height: 32px;
  color: #929292;
  font-weight: 400;
  margin-bottom: 2px;
}
.user-links li:last-child {
  margin-bottom: 0;
}
.user-links li a {
  position: relative;
  display: inline-block;
  color: inherit;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover {
  color: #ffffff;
}
.user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover:before {
  width: 100%;
}
.user-links.style-two li {
  padding-left: 0;
}
.user-links.style-two li:before {
  display: none;
}
.user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.user-links.two-column li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.recent-post {
  position: relative;
  margin-bottom: 30px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .inner {
  position: relative;
}
.recent-post .inner .post-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #adadad;
  line-height: 30px;
  margin-bottom: 23px;
  padding-left: 25px;
}
.recent-post .inner .post-info i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  margin-right: 10px;
  color: var(--theme-color1);
}
.recent-post .inner .post-title {
  color: var(--theme-color-light);
  letter-spacing: -0.8px;
  margin-bottom: 0;
}

/* Subscribe Form */
.subscribe-form {
  position: relative;
}
.subscribe-form .form-group {
  position: relative;
}
.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="email"] {
  position: relative;
  display: block;
  height: 72px;
  width: 100%;
  font-size: 16px;
  line-height: 32px;
  color: var(--theme-color-light);
  padding: 20px 30px;
  background: transparent;
  border: 1px solid #333f4d;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.subscribe-form .form-group input[type="text"]::-webkit-input-placeholder,
.subscribe-form .form-group input[type="email"]::-webkit-input-placeholder {
  color: #fff;
}
.subscribe-form .form-group input[type="text"]::-moz-placeholder,
.subscribe-form .form-group input[type="email"]::-moz-placeholder {
  color: #fff;
}
.subscribe-form .form-group input[type="text"]:-ms-input-placeholder,
.subscribe-form .form-group input[type="email"]:-ms-input-placeholder {
  color: #fff;
}
.subscribe-form .form-group input[type="text"]::-ms-input-placeholder,
.subscribe-form .form-group input[type="email"]::-ms-input-placeholder {
  color: #fff;
}
.subscribe-form .form-group input[type="text"]::placeholder,
.subscribe-form .form-group input[type="email"]::placeholder {
  color: #fff;
}
.subscribe-form .form-group .theme-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px 30px;
  line-height: 32px;
  border-radius: 0 10px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*=== Footer Bottom ===*/
.footer-bottom {
  position: relative;
  z-index: 3;
  border-top: 1px solid #333f4d;
  padding: 30px 0;
}
.footer-bottom .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer-bottom .copyright-text {
  position: relative;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
}
.footer-bottom .copyright-text a {
  color: inherit;
}
.footer-bottom .copyright-text a:hover {
  color: #ffffff;
}
.footer-bottom .footer-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.footer-bottom .footer-nav li {
  font-size: 16px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 30px;
}
.footer-bottom .footer-nav li:first-child {
  margin-left: 0;
}
.footer-bottom .footer-nav li a {
  color: inherit;
}
.footer-bottom .footer-nav li a:hover {
  color: var(--theme-color-light);
}

.footer-style-one {
  background-color: var(--theme-color-dark);
  /* Subscribe Form Three */
}
.footer-style-one .about-widget {
  margin-top: 14px;
}
.footer-style-one .about-widget .logo {
  position: relative;
  top: -20px;
  /* margin-bottom: 30px; */
}
.footer-style-one .about-widget .text {
  margin-bottom: 47px;
  font-size: 16px;
  color: #adadad;
}
.footer-style-one .about-widget .social-icon-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-style-one .about-widget .social-icon-two li {
  margin-right: 6px;
}
.footer-style-one .about-widget .social-icon-two li:last-child {
  margin-right: 0;
}
.footer-style-one .about-widget .social-icon-two li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #adadad;
  border-radius: 50%;
  border: 1px solid #adadad;
  display: inline-block;
}
.footer-style-one .about-widget .social-icon-two li a:hover {
  background-color: var(--theme-color1);
  border-color: transparent;
  color: #fff;
}
.footer-style-one .links-widget {
  margin-bottom: 50px;
}
.footer-style-one .links-widget li {
  padding-left: 27px;
}
.footer-style-one .links-widget li a {
  color: #adadad;
}
.footer-style-one .links-widget li i {
  position: absolute;
  left: -27px;
}
.footer-style-one .news-widget .text {
  margin-bottom: 18px;
  color: #adadad;
}
.footer-style-one .news-widget .text i {
  margin-right: 20px;
}
.footer-style-one
  .info-widget
  .widget-content
  .recent-post
  .inner
  .post-info:first-child {
  margin-bottom: 0;
}
.footer-style-one .subscribe-form-three {
  position: relative;
  margin-bottom: 40px;
}
.footer-style-one .subscribe-form-three .form-group {
  position: relative;
}
.footer-style-one .subscribe-form-three .form-group input[type="text"],
.footer-style-one .subscribe-form-three .form-group input[type="email"] {
  position: relative;
  display: block;
  height: 50px;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: var(--theme-color2);
  padding: 20px 65px 20px 18px;
  background: unset;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid #303030;
}
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="text"]::-webkit-input-placeholder,
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="email"]::-webkit-input-placeholder {
  color: var(--theme-color2);
}
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="text"]::-moz-placeholder,
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="email"]::-moz-placeholder {
  color: var(--theme-color2);
}
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="text"]:-ms-input-placeholder,
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="email"]:-ms-input-placeholder {
  color: var(--theme-color2);
}
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="text"]::-ms-input-placeholder,
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="email"]::-ms-input-placeholder {
  color: var(--theme-color2);
}
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="text"]::placeholder,
.footer-style-one
  .subscribe-form-three
  .form-group
  input[type="email"]::placeholder {
  color: var(--theme-color2);
}
.footer-style-one .subscribe-form-three .form-group .theme-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 5px;
  width: 50px;
  height: 50px;
  line-height: 21px;
  text-align: center;
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}
.footer-style-one .subscribe-form-three .form-group .theme-btn::before {
  background-color: var(--theme-color-light);
}
.footer-style-one .subscribe-form-three .form-group .theme-btn:hover {
  color: var(--theme-color1);
}
.footer-style-one .subscribe-form-three .form-group .theme-btn i {
  margin-left: 0;
}
.footer-style-one .footer-bottom {
  border: 0;
  padding: 0;
}
.footer-style-one .footer-bottom .inner-container {
  padding: 14px 30px 17px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  color: #adadad;
  background-color: #333333;
}


.footer-style-two {
  background-color: var(--theme-color-dark);
}
.footer-style-two .widgets-section {
  padding: 106px 0 44px;
}
.footer-style-two .about-widget {
  margin-top: 14px;
}
.footer-style-two .about-widget .logo {
  position: relative;
  margin-bottom: 30px;
  margin-top: -20px;
}
.footer-style-two .about-widget .text {
  margin-bottom: 34px;
  font-size: 16px;
  color: #adadad;
}
.footer-style-two .about-widget .social-icon-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-style-two .about-widget .social-icon-two li {
  margin-right: 6px;
}
.footer-style-two .about-widget .social-icon-two li:last-child {
  margin-right: 0;
}
.footer-style-two .about-widget .social-icon-two li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #adadad;
  border-radius: 50%;
  border: 1px solid #adadad;
  display: inline-block;
}
.footer-style-two .about-widget .social-icon-two li a:hover {
  background-color: var(--theme-color1);
  border-color: transparent;
  color: #fff;
}
.footer-style-two .links-widget {
  margin-bottom: 50px;
}
.footer-style-two .links-widget .widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-style-two .links-widget .widget-content .user-links {
  padding-right: 36px;
}
.footer-style-two .links-widget .widget-content .user-links:last-child {
  padding-right: 0;
}
.footer-style-two .links-widget .widget-content .user-links li {
  padding-left: 27px;
}
.footer-style-two .links-widget .widget-content .user-links li a {
  color: #adadad;
}
.footer-style-two .links-widget .widget-content .user-links li a:hover {
  color: var(--theme-color1);
}
.footer-style-two .links-widget .widget-content .user-links li i {
  position: absolute;
  left: -27px;
}
.footer-style-two .news-widget .news-info {
  position: relative;
  padding-left: 91px;
  margin-bottom: 18px;
}
@media (max-width: 1199.98px) {
  .footer-style-two .news-widget .news-info {
    padding-left: 87px;
  }
}
.footer-style-two .news-widget .news-info:hover .image img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.footer-style-two .news-widget .news-info:last-child {
  margin-bottom: 0;
}
.footer-style-two .news-widget .news-info .image {
  position: absolute;
  left: 0;
  top: 5px;
  overflow: hidden;
}
.footer-style-two .news-widget .news-info .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer-style-two .news-widget .news-info .title {
  color: #adadad;
  margin-bottom: -4px;
}
.footer-style-two .news-widget .news-info .date {
  font-size: 12px;
  color: var(--theme-color1);
}
.footer-style-two
  .info-widget
  .widget-content
  .recent-post
  .inner
  .post-info.two {
  margin-bottom: 0;
}
.footer-style-two .footer-bottom {
  border: 0;
  padding: 0;
  background-color: #333333;
}
.footer-style-two .footer-bottom .inner-container {
  padding: 14px 0 17px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  color: #adadad;
}
.footer-style-two .footer-bottom .inner-container a {
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
}
.footer-style-two .recent-post .inner .post-info {
  margin-bottom: 10px;
}
.footer-style-two .recent-post .inner .post-info:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .footer-style-two .news-widget .news-info {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .footer-bottom .copyright-text {
    line-height: 20px;
    margin-bottom: 15px;
  }
}
/***

====================================================================
    Products details
====================================================================

***/
.product-details .bxslider .image-box {
  position: relative;
  display: block;
  margin-right: 30px;
  margin-bottom: 10px;
}
.product-details .bxslider .image-box img {
  width: 100%;
}
.product-details .bxslider .thumb-box li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
}
.product-details .bxslider .thumb-box li:last-child {
  margin: 0px !important;
}
.product-details .bxslider .thumb-box li a {
  position: relative;
  display: inline-block;
}
.product-details .bxslider .thumb-box li a:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-details .bxslider .thumb-box li a.active:before {
  opacity: 1;
}
.product-details .bx-wrapper {
  margin-bottom: 30px;
}
.product-details .product-info .product-details__top {
  position: relative;
  display: block;
  margin-top: -8px;
}
.product-details .product-info .product-details__title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  margin: 0;
}
.product-details .product-info .product-details__title span {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-left: 20px;
  letter-spacing: 0;
}
.product-details .product-info .product-details__reveiw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 22px;
  padding-bottom: 20px;
  margin-bottom: 31px;
  border-bottom: 1px solid #e0e4e8;
}
.product-details .product-info .product-details__reveiw i {
  font-size: 16px;
  color: var(--review-color);
}
.product-details .product-info .product-details__reveiw i + i {
  margin-left: 4px;
}
.product-details .product-info .product-details__reveiw span {
  position: relative;
  top: 1px;
  line-height: 1;
  font-size: 16px;
  color: var(--theme-color1);
  margin-left: 18px;
}
.product-details .product-info .product-details__quantity-title {
  margin: 0;
  color: #222;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 20px;
}
.product-details .product-info .product-details__buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons {
    display: block;
  }
}
.product-details .product-info .product-details__buttons-1 {
  position: relative;
  display: block;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-1 {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.product-details .product-info .product-details__buttons-2 {
  position: relative;
  display: block;
  margin-left: 10px;
}
.product-details .product-info .product-details__buttons-2 .thm-btn {
  background-color: var(--theme-color2);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:before {
  background-color: var(--theme-color1);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:after {
  background-color: var(--theme-color1);
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 0;
  }
}
.product-details .product-info .product-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-details .product-info .product-details__social .title {
  position: relative;
  display: block;
}
.product-details .product-info .product-details__social .title h3 {
  color: #222;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.product-details .product-info .product-details__social .social-icon-one {
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .product-details .product-info .product-details__social .social-icon-one {
    margin-left: 0;
  }
}

.product-details__quantity {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}
.product-details__quantity .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 10px;
  height: 60px;
}
.product-details__quantity .quantity-box input {
  width: 98px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid #e0e4e8;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  padding-left: 30px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color1);
}
.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: #fff;
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--theme-color1);
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-details__quantity .quantity-box button:hover {
  color: var(--headings-color);
  background-color: var(--theme-color2);
}
.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.product-discription {
  position: relative;
  display: block;
}
.product-discription .product-description__title {
  font-size: 30px;
  margin-bottom: 27px;
}
.product-discription .product-description__text1 {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.product-discription .product-description__list {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.product-discription .product-description__list ul {
  position: relative;
  display: block;
}
.product-discription .product-description__list ul li {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .product-description__list ul li:last-child {
  margin-bottom: 0px;
}
.product-discription .product-description__list ul li p {
  margin: 0;
  font-weight: 600;
  color: var(--headings-color);
}
.product-discription .product-description__list ul li p span:before {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 17px;
  line-height: 17px;
  margin-right: 11px;
  top: 2px;
}
.product-discription .tabs-content .text p {
  margin-bottom: 17px;
}
.product-discription .tabs-content .text p:last-child {
  margin-bottom: 0px;
}
.product-discription .tab-btn-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 60px;
}
.product-discription .tab-btn-box:before {
  position: absolute;
  content: "";
  background-color: #e1e8e4;
  width: 100%;
  height: 1px;
  left: 0px;
  top: 28px;
}
.product-discription .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  color: #1e2434;
  text-align: center;
  padding: 14px 30px;
  background-color: #fff;
  border: 1px solid #e1e8e4;
  cursor: pointer;
  margin: 0px 8.5px;
  margin-bottom: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-discription .tab-btn-box .tab-btns li.active-btn {
  color: #fff;
  background-color: var(--theme-color1);
}
.product-discription .tab-btn-box .tab-btns li:last-child {
  margin-bottom: 0;
}
.product-discription .single-comment-box .inner-box {
  position: relative;
  display: block;
  background-color: #f4f5f4;
  padding: 34px 30px 34px 125px;
}
.product-discription .single-comment-box .inner-box .comment-thumb {
  position: absolute;
  left: 30px;
  top: 40px;
  border-radius: 50%;
  width: 80px;
}
.product-discription .single-comment-box .inner-box .comment-thumb img {
  width: 100%;
  border-radius: 50%;
}
.product-discription .single-comment-box .inner-box .rating {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .single-comment-box .inner-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .single-comment-box .inner-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .single-comment-box .inner-box h5 {
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.product-discription .single-comment-box .inner-box h5 span {
  font-weight: 400;
  text-transform: capitalize;
}
.product-discription .customer-comment {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.product-discription .comment-box {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 51px 60px 60px 60px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.product-discription .comment-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 23px;
}
.product-discription .comment-box .form-group {
  position: relative;
  margin-bottom: 15px;
}
.product-discription .comment-box .form-group label {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #707582;
  margin-bottom: 8px;
}
.product-discription .comment-box .column:last-child .form-group {
  margin-bottom: 0px;
}
.product-discription .comment-box .review-box {
  position: relative;
  display: block;
  margin-top: 8px;
}
.product-discription .comment-box .review-box p {
  position: relative;
  float: left;
  margin-right: 10px;
}
.product-discription .comment-box .review-box .rating {
  position: relative;
  float: left;
}
.product-discription .comment-box .review-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .comment-box .review-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .comment-box .custom-controls-stacked {
  position: relative;
  float: left;
}

.related-product h3 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-details__img {
    margin-bottom: 50px;
  }
  .product-details__title span {
    margin-left: 0;
    display: block;
  }
  .product-details__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 10px;
  }
  .product-details__social-link {
    margin-left: 0;
    margin-top: 20px;
  }
}
/***

====================================================================
 Categories Section
====================================================================

***/
.categories-section {
  position: relative;
  padding: 100px 0 70px;
}
.categories-section .bg-pattern {
  position: absolute;
  left: 0;
  top: -220px;
  width: 100%;
  height: 100%;
  content: "";
  /* background-image: url(../images/icons/pattern-7.png); */
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
}
.categories-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  background: var(--theme-color3);
  content: "";
  z-index: 1;
}
.categories-section:after {
  position: absolute;
  left: 0;
  bottom: -50px;
  height: 70px;
  width: 100%;
  z-index: 2;
  content: "";
  /* background-image: url(../images/icons/pattern-3.png); */
  background-repeat: no-repeat;
  background-position: center bottom;
}

.category-block {
  position: relative;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  padding: 40px 30px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block .inner-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center bottom;
  content: "";
}
.category-block .inner-box:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.category-block .inner-box:hover .image img {
  -webkit-transform: rotate(10deg) scale(1.2);
  transform: rotate(10deg) scale(1.2);
}
.category-block .inner-box.cat-bg-1:before {
  /* background-image: url(../images/icons/cat-1-bg.png); */
}
.category-block .inner-box.cat-bg-2:before {
  /* background-image: url(../images/icons/cat-2-bg.png); */
}
.category-block .inner-box.cat-bg-3:before {
  /* background-image: url(../images/icons/cat-3-bg.png); */
}
.category-block .inner-box.cat-bg-4:before {
  /* background-image: url(../images/icons/cat-4-bg.png); */
}
.category-block .image {
  position: relative;
  display: inline-block;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.category-block .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 {
  font-size: 20px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 10px;
}
.category-block h4 a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 a:hover {
  color: var(--theme-color1);
}
.category-block p {
  font-size: 14px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}

/***

====================================================================
    Categories Section Two
====================================================================

***/
.categories-section-two {
  position: relative;
  padding: 120px 0 90px;
}

.category-block-two {
  position: relative;
  padding-top: 70px;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block-two .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 18px;
}
.category-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 60px;
  width: 100%;
  border-radius: 10px;
  background: #e3eee5;
  content: "";
}
.category-block-two .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.category-block-two .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.category-block-two .inner-box:hover h4 a {
  color: var(--theme-color1);
}
.category-block-two .content {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 40px 40px;
  z-index: 1;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
}
.category-block-two .content:before {
  position: absolute;
  top: -88px;
  left: 0;
  width: 180px;
  height: 180px;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  background: #e3eee5;
  content: "";
}
.category-block-two .content:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  font-size: 30px;
  line-height: 1em;
  color: #e8f3ea;
  height: 15px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  content: attr(data-text);
}
.category-block-two .image {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: -70px;
}
.category-block-two .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 120%;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgb(255, 255, 255))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}
.category-block-two h4 {
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 15px;
}
.category-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two p {
  font-size: 16px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}
.category-block-two .link {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #e8f3ea;
  border-radius: 50%;
  line-height: 50px;
  margin-top: 25px;
  color: #608174;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .link:hover {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.category-block-two.child-two .link {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:before {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:hover h4 a {
  color: #e69da2;
}
.category-block-two.child-two .content:before {
  background: #f0e2e3;
}
.category-block-two.child-two .content:after {
  color: #f0e2e3;
}
.category-block-two.child-three .link {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:before {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:hover h4 a {
  color: #c9b579;
}
.category-block-two.child-three .content:before {
  background: #f1ede1;
}
.category-block-two.child-three .content:after {
  color: #f1ede1;
}

/***

====================================================================
    Products Section
====================================================================

***/
.products-section {
  position: relative;
  padding: 120px 0;
}

.products-section .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 670px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.products-section .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #113629;
  opacity: 0.7;
  content: "";
}

.products-section .bg-image:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  /* background-image: url(../images/icons/pattern-8.png); */
  background-position: center bottom;
  content: "";
}

.products-section .sec-title h2 {
  font-size: 60px;
}

.products-section .sec-title .theme-btn {
  margin-top: 30px;
}

.products-box {
  max-width: 1530px;
  position: relative;
  padding: 120px 60px 90px;
  margin: 120px auto 0;
  background-color: #f7f5ee;
  overflow: hidden;
  border-radius: 10px;
}

.products-box:before {
  position: absolute;
  left: -90px;
  bottom: 0;
  height: 70%;
  width: 100%;
  /* background: url(../images/icons/shape-7.png) top left no-repeat; */
  content: "";
}

.products-box .sec-title {
  margin-bottom: 30px;
}

.products-box .outer-box {
  position: relative;
  padding-right: 400px;
}

.products-box .outer-box .banner-box-two {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-box-two {
  position: relative;
}

.banner-box-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(22, 67, 51)),
    to(rgba(229, 229, 229, 0))
  );
  background: linear-gradient(
    to bottom,
    rgb(22, 67, 51) 0%,
    rgba(229, 229, 229, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--theme-color3)", endColorstr="#00e5e5e5",GradientType=0 );
  content: "";
}

.banner-box-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 370px;
  background: var(--theme-color3);
  border-radius: 10px;
  min-height: 440px;
  text-align: center;
  overflow: hidden;
  padding: 20px 20px;
}

.banner-box-two .title {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner-box-two .title strong {
  font-size: 60px;
  line-height: 1em;
  color: var(--theme-color2);
  font-weight: 400;
}

.banner-box-two h4 {
  font-size: 30px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.product-block-two {
  position: relative;
  margin-bottom: 30px;
}

.product-block-two .inner-box {
  position: relative;
  border: 2px solid transparent;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px 20px;
  padding-left: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 150px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover {
  border: 2px solid var(--theme-color1);
}

.product-block-two .image {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 50%;
  overflow: hidden;
  height: 110px;
  width: 110px;
  border: 1px solid #e4e1d6;
  margin-bottom: 0px;
}

.product-block-two .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover .image img {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.product-block-two h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 5px;
}

.product-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--theme-color1);
  font-weight: 600;
}

.product-block-two .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block-two .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #ffc737;
}

.products-carousel .owl-nav {
  display: none;
}

.products-carousel .owl-dots {
  position: absolute;
  right: 0;
  top: -80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 8;
}

.products-carousel .owl-dot {
  position: relative;
  height: 7px;
  width: 7px;
  display: block;
  background: #879d91;
  margin-right: 5px;
  border-radius: 5px;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.products-carousel .owl-dot.active {
  background: var(--theme-color3);
}

/***

====================================================================
    Featured Products
====================================================================

***/
.featured-products {
  position: relative;
  padding: 120px 0 90px;
}

.featured-products .bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background: url(../images/icons/pattern-7.png) left bottom no-repeat; */
  background-size: 700px;
}

/*=== Mixitup Gallery ===*/
.featured-products .filters {
  margin-bottom: 40px;
  text-align: center;
}

.featured-products .filters .filter-tabs {
  position: relative;
  display: inline-block;
}

.featured-products .filters li {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 0px 2px 10px;
  cursor: pointer;
  color: #797f7d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 5px 0;
  padding: 5px 12px 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.featured-products .filters li:last-child {
  margin-right: 0;
}

.featured-products .filters .filter.active,
.featured-products .filters .filter:hover {
  background-color: var(--theme-color1);
  color: #fff;
}

.featured-products .filters li:before {
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 2px;
  width: 100%;
  content: "";
  background-color: #fff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(
    0.86,
    0,
    0.07,
    1
  ); /* easeInOutQuint */
}

.featured-products .filters li.active:before,
.featured-products .filters li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block.mix {
  display: none;
}

.product-block .inner-box {
  position: relative;
  border: 2px solid #e4e1d5;
  border-radius: 10px;
  text-align: center;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover {
  border: 2px solid var(--theme-color1);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.product-block .image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0px;
}

.product-block .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgb(255, 255, 255))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}

.product-block .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.product-block .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.product-block .content {
  position: relative;
  padding: 30px 30px 30px;
}

.product-block h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color2);
  font-weight: 700;
  margin-bottom: 5px;
}

.product-block h4 a {
  color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block h4 a:hover {
  color: var(--theme-color1);
}

.product-block .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-gray-silver);
  font-weight: 600;
}

.product-block .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #ffc737;
}

.product-block .tag {
  position: absolute;
  top: 30px;
  left: 20px;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  background: #fd5f5c;
  font-weight: 400;
  padding: 0 12px;
  border-radius: 3px;
  z-index: 9;
  font-style: italic;
  text-transform: uppercase;
}

.product-block .icon-box {
  position: absolute;
  right: 20px;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .icon-box {
  top: 20px;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

.product-block .ui-btn {
  background-color: var(--theme-color1);
  border-radius: 50px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .ui-btn:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}

.product-block .cat {
  display: block;
  font-size: 18px;
  color: #707070;
  font-style: italic;
  font-family: "Jost", sans-serif;
  margin-bottom: 5px;
}

/*
 * shop-catalog-layouts.scss
 * -----------------------------------------------
*/
table.tbl-shopping-cart .product-thumbnail,
table.cart-total .product-thumbnail {
  min-width: 64px;
}
table.tbl-shopping-cart img,
table.cart-total img {
  width: 64px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
table.tbl-shopping-cart th,
table.tbl-shopping-cart td,
table.cart-total th,
table.cart-total td {
  vertical-align: middle;
  border-left: 1px solid #e3e3e3;
  padding: 20px 30px;
}
table.tbl-shopping-cart .product-name a,
table.cart-total .product-name a {
  color: var(--headings-color);
}
table.tbl-shopping-cart .product-name .variation,
table.cart-total .product-name .variation {
  font-size: 0.9rem;
  list-style: none;
}
table.tbl-shopping-cart .product-remove a,
table.cart-total .product-remove a {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background-color: #757575;
  color: #ffffff;
  text-align: center;
}
table.tbl-shopping-cart .coupon-form .apply-button,
table.cart-total .coupon-form .apply-button {
  position: relative;
  display: inline-block;
  color: #1e2434;
  background: #f4f5f4;
  padding: 15px 29px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
table.tbl-shopping-cart .coupon-form .apply-button:hover,
table.cart-total .coupon-form .apply-button:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}

table.tbl-shopping-cart > thead > tr > th,
table.tbl-shopping-cart > tbody > tr > th,
table.tbl-shopping-cart > tfoot > tr > th {
  color: #444;
}

.payment-method .accordion-box .block {
  background: #f4f5f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
}
.payment-method .accordion-box .block .acc-content .payment-info {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px 30px 10px 30px;
}
.payment-method .accordion-box .block:last-child {
  margin-bottom: 0px;
}
.payment-method .accordion-box .block .acc-btn {
  padding: 19px 30px 22px 30px;
}
.payment-method .accordion-box .block .acc-btn .icon-outer {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 30px;
  height: auto;
  /* line-height: 65px; */
  font-size: 28px;
  color: #1e2434;
}
.payment-method .accordion-box .block .acc-btn.active .icon-outer {
  color: var(--text-color-bg-theme-color2);
}
.payment-method .payment-method h3 {
  margin-bottom: 32px;
}

.shop-sidebar {
  position: relative;
  display: block;
}
.shop-sidebar .sidebar-search {
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-search .search-form .form-group {
  position: relative;
  margin: 0px;
}
.shop-sidebar .sidebar-search .search-form .form-group input[type="search"] {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: var(--theme-light-background);
  border: 1px solid var(--theme-light-background);
  border-radius: 5px;
  color: #646578;
  padding: 10px 60px 10px 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button {
  position: absolute;
  display: inline-block;
  top: 5px;
  right: 5px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: var(--theme-color1);
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.shop-sidebar .sidebar-widget {
  position: relative;
  display: block;
  background-color: var(--theme-light-background);
  padding: 35px 30px 37px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .sidebar-widget .widget-title {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.shop-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}
.shop-sidebar .category-widget .category-list li:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  color: #646578;
  font-weight: 400;
  padding-left: 20px;
}
.shop-sidebar .category-widget .category-list li a:before {
  position: absolute;
  content: "\f0da";
  font-family: "Font Awesome 6 Pro";
  left: 0px;
  top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color1);
}
.shop-sidebar .category-widget .category-list li a:hover {
  color: var(--theme-color1);
}
.shop-sidebar .price-filters .widget-title {
  margin-bottom: 28px;
}
.shop-sidebar .post-widget {
  padding-bottom: 9px;
}
.shop-sidebar .post-widget .post {
  position: relative;
  padding-left: 90px;
  padding-bottom: 24px;
  margin-bottom: 23px;
  min-height: 108px;
  border-bottom: 1px solid #e1e1e1;
}
.shop-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.shop-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 7px;
  width: 70px;
  height: 70px;
  border: 1px solid #d0d4dd;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 5px;
}
.shop-sidebar .post-widget .post a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #646578;
  margin-bottom: 7px;
}
.shop-sidebar .post-widget .post .price {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0a267a;
}
.shop-sidebar .post-widget .post:hover .post-thumb {
  border-color: var(--theme-color1);
}
.shop-sidebar .post-widget .post:hover a {
  color: var(--theme-color1);
}

.range-slider {
  position: relative;
}
.range-slider .title {
  line-height: 26px;
  position: relative;
  display: inline-block;
  margin-right: 4px;
}
.range-slider .title:before {
  position: absolute;
  content: "$";
  left: -5px;
  top: -19px;
  color: #646578;
  font-size: 18px;
}
.range-slider p {
  position: relative;
  display: inline-block;
  color: #646578;
  margin-right: 10px !important;
}
.range-slider .input {
  color: #646578;
  max-width: 75px;
  font-size: 18px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}
.range-slider .input input {
  background: none;
  color: #646578;
  font-size: 15px;
  text-align: left;
}
.range-slider .ui-widget.ui-widget-content {
  height: 4px;
  border: none;
  margin-bottom: 14px;
  background-color: #d0d4dd;
  border-radius: 2px;
}
.range-slider .ui-slider .ui-slider-range {
  top: 0px;
  height: 4px;
  background-color: var(--theme-color1);
}
.range-slider .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
  position: absolute;
}
.range-slider .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider .ui-widget-content .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
}
.range-slider .ui-widget-content .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider input[type="submit"] {
  position: relative;
  display: block;
  background: var(--theme-color1);
  color: #ffffff;
  float: right;
  text-align: center;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.range-slider input[type="submit"]:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}

/*** 

====================================================================
Page Title
====================================================================

***/
/* @-webkit-keyframes "ripple" {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes "ripple" {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
} */
.page-title {
  background-color: var(--theme-color2);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 120px 0 0;
  min-height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-title:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(17, 32, 33, 0.9)),
    to(transparent)
  );
  background: linear-gradient(180deg, rgba(17, 32, 33, 0.9), transparent);
}
.page-title .title {
  font-size: 64px;
  color: #ffffff;
  margin-bottom: 17px;
}
@media only screen and (max-width: 768px) {
  .page-title .title {
    font-size: 34px;
  }
}
.page-title .text {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  max-width: 520px;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}
.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.page-breadcrumb li:after {
  position: absolute;
  content: "\f105";
  right: -6px;
  top: 1px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
  color: #ffffff;
}
.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a {
  color: var(--theme-color3);
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.page-breadcrumb li a:hover {
  color: #ffffff;
}

.page-title.style-two {
  background-position: center center;
}
.page-title.style-two .page-breadcrumb-outer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 8;
}
.page-title.style-two .page-breadcrumb li {
  color: rgb(7, 7, 16);
  font-weight: 600;
}
.page-title.style-two .page-breadcrumb li:after {
  color: rgb(7, 7, 16);
}
.page-title.style-two .page-breadcrumb li a {
  color: rgba(7, 7, 16, 0.6);
}
.page-title.style-two .page-breadcrumb li a:hover {
  color: rgb(7, 7, 16);
}

.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #ffffff;
  color: #ff6d2e;
  z-index: 1;
  padding-left: 0px;
  font-size: 14px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}
.play-now .ripple:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}
.error-page__inner .error-page__title-box {
  position: relative;
  display: block;
}
.error-page__inner .error-page__title {
  position: relative;
  display: inline-block;
  font-size: 280px;
  line-height: 280px;
  margin-bottom: 0;
  color: var(--theme-color1);
}
.error-page__inner .error-page__sub-title {
  font-size: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -16px;
}
.error-page__inner .error-page__text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.error-page__inner .error-page__form {
  position: relative;
  display: block;
  margin: 42px auto 20px;
}
.error-page__inner .error-page__form input[type="search"] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  padding-left: 50px;
  padding-right: 75px;
  border-radius: 7px;
}
.error-page__inner .error-page__form button[type="submit"] {
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.error-page__inner .error-page__form-input {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

/***

====================================================================
        Contact
====================================================================

***/
.contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
}
.contact-details__info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-details__info li .icon {
  height: 80px;
  width: 80px;
  background-color: var(--theme-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .icon span {
  color: var(--text-color-bg-theme-color1);
  font-size: 25px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li:hover .icon {
  background-color: var(--theme-color2);
}
.contact-details__info li:hover .icon span {
  color: var(--text-color-bg-theme-color2);
}
.contact-details__info li:hover .text a {
  color: var(--notech-base);
}
.contact-details__info li:hover .text a span {
  color: var(--notech-gray);
}
.contact-details__info li .text {
  margin-left: 30px;
  text-align: left;
}
.contact-details__info li .text p {
  font-size: 14px;
  line-height: 24px;
}
.contact-details__info li .text a {
  font-size: 18px;
  color: var(--notech-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .text span {
  font-size: 20px;
  color: var(--notech-black);
}
.contact-details__info li + li {
  margin-top: 19px;
}

.map {
  height: 550px;
}

/***
=============================================
    Services Details
=============================================
***/
.service-sidebar {
  position: relative;
  display: block;
  max-width: 365px;
  width: 100%;
}
.service-sidebar .service-sidebar-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.service-sidebar .service-sidebar-single-services {
  position: relative;
  display: block;
  background: #f6f4ec;
  border-radius: 10px;
  padding: 35px 30px 25px;
}
.service-sidebar .service-sidebar-single-services .title {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
}
.service-sidebar .service-sidebar-single-services .title h3 {
  color: var(--headings-color);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.service-sidebar .service-sidebar-single-services ul {
  position: relative;
  display: block;
  margin-top: 10px;
}
.service-sidebar .service-sidebar-single-services ul li {
  position: relative;
  display: block;
  margin-bottom: 5px;
  margin-top: -10px;
}
.service-sidebar .service-sidebar-single-services ul li:last-child {
  margin-bottom: 0;
}
.service-sidebar .service-sidebar-single-services ul li a {
  position: relative;
  display: block;
  color: var(--agriox-color-1, #687469);
  font-size: 18px;
  padding: 22px 20px 22px;
  border-radius: 10px;
  background: transparent;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.service-sidebar .service-sidebar-single-services ul li a:hover {
  color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-services ul li a:hover::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li a:hover i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(90deg);
  transform: perspective(400px) rotateX(90deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.service-sidebar .service-sidebar-single-services ul li a i {
  font-size: 16px;
}
.service-sidebar .service-sidebar-single-services ul li.current a::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li.current a i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li.current:first-child {
  margin-top: 20px;
}
.service-sidebar .service-sidebar-single-services ul li.current:last-child {
  margin-bottom: 35px;
}
.service-sidebar .service-sidebar-single-contact-box {
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 50px 0px 44px;
  z-index: 1;
}
.service-sidebar .service-sidebar-single-contact-box::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(109, 140, 84, 0.93);
  border-radius: 10px;
  content: "";
  z-index: -1;
}
.service-sidebar .service-sidebar-single-contact-box .icon {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  background: var(--theme-color2);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover {
  background-color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover span::before {
  color: #fff;
}
.service-sidebar .service-sidebar-single-contact-box .icon span::before {
  position: relative;
  display: inline-block;
  color: var(--headings-color);
  font-size: 30px;
  line-height: 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .title {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 42px;
}
.service-sidebar .service-sidebar-single-contact-box .title h2 {
  color: #ffffff;
  font-size: 36px;
}
.service-sidebar .service-sidebar-single-contact-box .phone {
  font-size: 24px;
  line-height: 34px;
}
.service-sidebar .service-sidebar-single-contact-box .phone a {
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .phone a:hover {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-contact-box p {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}
.service-sidebar .service-sidebar-single-btn {
  position: relative;
  display: block;
}
.service-sidebar .service-sidebar-single-btn .thm-btn {
  font-size: 16px;
  padding: 13px 50px 28px;
}
.service-sidebar .service-sidebar-single-btn .thm-btn span::before {
  position: relative;
  display: inline-block;
  top: 13px;
  color: #334b35;
  font-size: 40px;
  padding-right: 25px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}
.service-sidebar .service-sidebar-single-btn .thm-btn:hover span::before {
  color: #ffffff;
}
.service-sidebar .banner-widget {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.service-sidebar .banner-widget .widget-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 45px 30px 40px 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .widget-content .shape {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 278px;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-sidebar .banner-widget .content-box {
  position: relative;
  max-width: 200px;
  width: 100%;
}
.service-sidebar .banner-widget .content-box .icon-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  text-align: center;
  border-radius: 5px;
  margin-bottom: 23px;
}
.service-sidebar .banner-widget .content-box .icon-box .icon-shape {
  position: absolute;
  top: -15px;
  right: -38px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .content-box h3 {
  display: block;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.service-sidebar .banner-widget .content-box .theme-btn-two:hover {
  background: #0a267a;
}
.service-sidebar .service-sidebar-single-btn .theme-btn {
  padding: 20px 50px;
}
.service-sidebar .service-sidebar-single-btn .theme-btn .btn-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.service-sidebar .service-sidebar-single-btn .theme-btn span::before {
  position: relative;
  display: inline-block;
  font-size: 36px;
  padding-right: 25px;
  margin-top: 7px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}

.services-details__content .feature-list .single-item {
  position: relative;
  display: block;
  border: 1px solid #e1e8e4;
  padding: 16px 30px 16px 53px;
  margin-bottom: 20px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .icon-box {
  color: var(--theme-color1);
  position: absolute;
  left: 20px;
  top: 16px;
  font-size: 18px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .title {
  display: block;
  margin: 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}
.services-details__content .feature-list .single-item:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.services-details__content .feature-list .single-item:hover .icon-box {
  color: var(--text-color-bg-theme-color2);
}

.service-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.service-list li:last-child {
  margin-bottom: 0px;
}
.service-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--headings-color);
  font-weight: 600;
  background-color: #fff;
  padding: 17px 20px 17px 50px;
  -webkit-box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.service-list li a:hover,
.service-list li a.current {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
  padding-left: 80px;
}
.service-list li a:hover i,
.service-list li a.current i {
  width: 60px;
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}
.service-list li i {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  background-color: #f6f4ec;
  text-align: center;
  font-size: 16px;
  color: #707582;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar-service-list {
  margin: 0;
}
.sidebar-service-list li + li {
  margin-top: 10px;
}
.sidebar-service-list li a {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background-color: #f5faff;
  border-radius: 15px;
  padding: 19px 40px;
}
.sidebar-service-list li a:hover {
  color: var(--theme-color2);
}
.sidebar-service-list li a:hover i {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}
.sidebar-service-list li a i {
  height: 32px;
  width: 45px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #191825;
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 15px;
}
.sidebar-service-list li.current a {
  color: var(--theme-color2);
}
.sidebar-service-list li.current a i {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}

.service-details-help {
  position: relative;
  display: block;
  padding: 57px 55px 50px;
  margin-top: 30px;
  text-align: center;
  z-index: 1;
  background-color: var(--theme-color2);
  border-radius: 15px;
  overflow: hidden;
}

.help-shape-1 {
  position: absolute;
  bottom: -215px;
  left: -95px;
  width: 220px;
  height: 500px;
  background-color: #303030;
  mix-blend-mode: soft-light;
  border-radius: 150px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}

.help-shape-2 {
  position: absolute;
  top: -118px;
  right: -130px;
  width: 180px;
  height: 350px;
  background-color: #fff;
  mix-blend-mode: soft-light;
  border-radius: 186px;
  -webkit-transform: rotate(48deg);
  transform: rotate(48deg);
}

.help-icon {
  height: 73px;
  width: 73px;
  background-color: #fff;
  color: #191825;
  font-size: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-icon:hover {
  background-color: var(--theme-color1);
  color: #fff;
}

.help-title {
  font-size: 38px;
  color: #fff;
  line-height: 40px;
  margin-bottom: 21px;
  font-weight: 700;
}

.help-contact {
  position: relative;
  display: block;
  margin-top: 21px;
}
.help-contact p {
  font-size: 14px;
  color: #fff;
  margin: 0;
  line-height: 32px;
  font-weight: 600;
  opacity: 0.7;
}
.help-contact a {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-contact a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details__img {
  position: relative;
  display: block;
}
.project-details__img img {
  width: 100%;
  border-radius: 10px;
}

.project-details__content {
  position: relative;
  display: block;
}

.project-details__content-left {
  position: relative;
  display: block;
  margin-top: 31px;
}

.project-details__content-right {
  position: relative;
  display: block;
  margin-top: 40px;
}

.project-details__details-box {
  position: relative;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 43px 50px 50px;
  z-index: 1;
}

.project-details__details-list {
  position: relative;
  display: block;
}
.project-details__details-list li {
  position: relative;
  display: block;
}
.project-details__details-list li + li {
  margin-top: 24px;
}

.project-details__client {
  font-size: 16px;
  color: #838d9e;
  line-height: 24px;
  margin: 0;
}

.project-details__name {
  font-size: 16px;
  line-height: 24px;
}

.project-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.project-details__social a:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.project-details__social a + a {
  margin-left: 10px;
}

.project-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #ece9e0;
  border-bottom: 1px solid #ece9e0;
  padding: 30px 0;
  margin-top: 117px;
}

.project-details__pagination {
  position: relative;
  display: block;
}
.project-details__pagination li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-details__pagination li a {
  font-size: 14px;
  color: #757873;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.project-details__pagination li a:hover {
  color: var(--theme-color1);
}
.project-details__pagination li a:hover i {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  border: 2px solid var(--theme-color1);
}
.project-details__pagination li .content {
  position: relative;
  display: block;
}
.project-details__pagination li.next {
  float: left;
  position: relative;
}
.project-details__pagination li.next i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}
.project-details__pagination li.next .content {
  text-align: left;
}
.project-details__pagination li.previous {
  position: relative;
  float: right;
}
.project-details__pagination li.previous i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}
.project-details__pagination li.previous .content {
  text-align: right;
}

/***

====================================================================
    Blog Details
====================================================================

***/
.blog-details {
  position: relative;
  display: block;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
  border-radius: 10px;
}
.blog-details__img img {
  width: 100%;
  border-radius: 10px;
}

.blog-details__date {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color1);
  text-align: center;
  padding: 21px 24px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.blog-details__date .day {
  font-size: 16px;
  color: #fff;
  font-weight: var(--body-font-weight-bold);
  line-height: 16px;
}
.blog-details__date .month {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: var(--body-font-weight-bold);
  color: #fff;
  line-height: 12px;
  text-transform: uppercase;
}

.blog-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.blog-details__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details__meta li + li {
  margin-left: 18px;
}
.blog-details__meta li a {
  font-size: 15px;
  color: #777;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.blog-details__meta li a:hover {
  color: var(--theme-color1);
}
.blog-details__meta li a i {
  color: var(--theme-color1);
  margin-right: 6px;
}

.blog-details__title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 21px;
  font-weight: var(--body-font-weight-bold);
}

.blog-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 49px;
  border-top: 1px solid #ece9e0;
}
@media only screen and (max-width: 767px) {
  .blog-details__bottom {
    gap: 30px;
  }
}
.blog-details__bottom p {
  margin: 0;
}

.blog-details__tags span {
  color: #0e2207;
  font-size: 20px;
  margin-right: 14px;
  font-weight: var(--body-font-weight-bold);
}
.blog-details__tags a {
  position: relative;
  font-size: 12px;
  background-color: var(--theme-color1);
  color: #fff;
  display: inline-block;
  padding: 5px 30px 5px;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 35px;
}
.blog-details__tags a:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color1);
  text-decoration: none;
}
.blog-details__tags a + a {
  margin-left: 6px;
}

.blog-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.blog-details__social-list a:hover {
  color: var(--text-color-bg-theme-color2);
}
.blog-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.blog-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--theme-color2);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}
.blog-details__social-list a + a {
  margin-left: 10px;
}

.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}

.blog-details__pagenation {
  position: relative;
  display: block;
}
.blog-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: #0e2207;
  font-weight: var(--body-font-weight-bold);
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 52px;
  padding-bottom: 52px;
  border-radius: 10px;
}
.blog-details__pagenation li:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.blog-details__pagenation li + li {
  margin-left: 30px;
}

/* Nav Links */
.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 53px;
}
@media only screen and (max-width: 767px) {
  .nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
}
.nav-links .prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: calc(50% - 15px);
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .nav-links .prev {
    width: 100%;
  }
}
.nav-links .prev .thumb {
  margin-right: 20px;
}
.nav-links .next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  width: calc(50% - 15px);
}
@media only screen and (max-width: 767px) {
  .nav-links .next {
    width: 100%;
  }
}
.nav-links .next .thumb {
  margin-left: 20px;
}
.nav-links > div {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.nav-links > div .thumb {
  display: inline-block;
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.nav-links > div .thumb a {
  display: inline-block;
}
.nav-links > div > a {
  display: inline-block;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 1.637;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 52px 50px;
  border-radius: 10px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .nav-links > div > a {
    padding: 30px;
  }
}
.nav-links > div > a:hover {
  color: #fff;
  background-color: var(--theme-color1);
}

/*** 

====================================================================
Sidebar
====================================================================

***/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: var(--h4-font-weight);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}
.sidebar__search-form input[type="search"] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--theme-color1);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-left: 50px;
  height: 74px;
  width: 100%;
  padding-right: 80px;
  border-radius: 10px;
}
.sidebar__search-form input[type="search"]::-webkit-input-placeholder {
  color: var(--text-color-bg-theme-color1);
  opacity: 1;
}
.sidebar__search-form input[type="search"]::-webkit-input-placeholder,
.sidebar__search-form input[type="search"]:-ms-input-placeholder,
.sidebar__search-form input[type="search"]::-ms-input-placeholder,
.sidebar__search-form input[type="search"]::placeholder {
  color: var(--text-color-bg-theme-color1);
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form input[type="search"] {
    padding-left: 30px;
  }
}
.sidebar__search-form button[type="submit"] {
  background-color: transparent;
  color: #fff;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form button[type="submit"] {
    width: 42px;
  }
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__post {
    padding: 30px;
  }
}
.sidebar__post .sidebar__title {
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__title {
    margin-left: 0;
  }
}
.sidebar__post .sidebar__post-list {
  margin: 0;
}
.sidebar__post .sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__post-list li {
    padding: 16px 0;
  }
}
.sidebar__post .sidebar__post-list li:hover {
  background-color: #ffffff;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list li + li {
  margin-top: 11px;
}
.sidebar__post .sidebar__post-list .sidebar__post-image {
  margin-right: 20px;
  -webkit-box-flex: 70px;
  -ms-flex: 70px 0 0px;
  flex: 70px 0 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-image > img {
  width: 80px;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content {
  position: relative;
  top: -3px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 a {
  color: #0e2207;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta {
  font-size: 14px;
  font-weight: 500;
  color: #757873 !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta i {
  color: var(--theme-color1);
  font-size: 14px;
  padding-right: 3px;
}

.sidebar__category {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  padding: 45px 30px 38px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__category {
    padding: 30px 15px 30px;
  }
}
.sidebar__category .sidebar__title {
  padding-left: 20px;
  margin-bottom: 9px;
}
.sidebar__category-list {
  margin: 0;
}
.sidebar__category-list li + li {
  margin-top: 4px;
}
.sidebar__category-list li a {
  color: #757873;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 12px 20px;
  font-weight: 500;
  border-radius: 10px;
}
.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li a:hover span {
  color: #ffcd1e;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-color2);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}
.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  border-radius: 10px;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: #ffcd1e;
}

.sidebar__tags {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 45px 50px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__tags {
    padding: 30px;
  }
}
.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}
.sidebar__tags-list a {
  font-size: 14px;
  color: #0e2207;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #ffffff;
  display: inline-block;
  padding: 5px 28px 5px;
  margin-left: 5px;
  border-radius: 30px;
}
.sidebar__tags-list a:hover {
  color: #fff;
  background: var(--theme-color1);
}
.sidebar__tags-list a + a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__comments {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 50px 43px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__comments {
    padding: 30px;
  }
}
.sidebar__comments .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}
.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}
.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--theme-color2);
  color: var(--headings-color);
}
.sidebar__comments-list li + li {
  margin-top: 23px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar__comments-text-box p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.sidebar__comments-text-box p span {
  color: #0e2207;
}
.sidebar__comments-text-box h5 {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  color: #757873;
  font-weight: 500;
  letter-spacing: 0;
}

/*** 

====================================================================
    Comments
====================================================================

***/
.comment-one .comment-one__title {
  margin-bottom: 30px;
}
.comment-one .comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ece9e0;
  padding-bottom: 60px;
  margin-bottom: 60px;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.comment-one .comment-one__content {
  position: relative;
  margin-left: 45px;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__content {
    margin-top: 20px;
    margin-left: 0;
  }
}
.comment-one .comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  color: var(--theme-black);
  margin-bottom: 24px;
}
.comment-one .comment-one__content p {
  font-size: 16px;
  font-weight: 500;
}
.comment-one .comment-one__btn {
  padding: 5px 30px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
}
.comment-one .comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
  -webkit-box-flex: 100px;
  -ms-flex: 100px 0 0px;
  flex: 100px 0 0;
}
.comment-one .comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
}

.team-details__top {
  padding: 0 0 120px;
}

.team-details-shape-1 {
  position: absolute;
  bottom: -270px;
  right: 0;
  opacity: 0.5;
  z-index: 2;
}
.team-details-shape-1 img {
  width: auto;
}

.team-details__top-left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.team-details__top-img {
  position: relative;
  display: block;
  border-radius: 30px;
}
.team-details__top-img img {
  width: 100%;
  border-radius: 30px;
}

.team-details__big-text {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: #eef0f6;
  letter-spacing: 0.35em;
  font-weight: 400;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 209px;
  left: -325px;
}

.team-details__top-right {
  position: relative;
  display: block;
  margin-left: 50px;
}
@media only screen and (max-width: 991px) {
  .team-details__top-right {
    margin-top: 70px;
    margin-left: 0;
  }
}

.team-details__top-content {
  position: relative;
  display: block;
  margin-top: -11px;
}

.team-details__top-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 3px;
}

.team-details__top-title {
  font-size: 16px;
  color: var(--theme-color1);
}

.team-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
}
.team-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color1);
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__social a:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.team-details__social a + a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 30px;
  color: var(--theme-color1);
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 30px;
}

.team-details__top-text-2 {
  padding-top: 23px;
  padding-bottom: 35px;
}

.team-details__bottom {
  position: relative;
  display: block;
  border-top: 1px solid #e4e5ea;
  padding-top: 110px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 70px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}

.team-details__bottom-left-text {
  padding-top: 30px;
}

.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 1px;
}
@media only screen and (max-width: 991px) {
  .team-details__bottom-right {
    margin-left: 0;
  }
}

.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
}
.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: #eef0f6;
  border-radius: 7px;
  margin-bottom: 22px;
}
.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 7px;
  background-color: var(--theme-color1);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}
.team-details__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--theme-color1);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}
.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}

.team-details__progress-single {
  position: relative;
  display: block;
}

.team-details__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--headings-color);
  margin-bottom: 6px;
}

.team-contact-form {
  background-color: #eef0f6;
}
.team-contact-form input[type="text"],
.team-contact-form input[type="email"] {
  background-color: #fff;
}
.team-contact-form textarea {
  background-color: #fff;
  height: 180px;
}
.imagewidthsmall{
  width: 50px;
}
.imagewith1{
  width: 55px;
}
.inner-column{

  justify-content: center;
  text-align: center;
  align-items: center;
}
@media (max-width: 700px){
  .paddingclass{
 
    padding: 60px 0px;
   
  }
  .booking-section{
    padding: 0px;
  }
  .contact-info{
    display: none;
  }
  .fontofslid{
    font-size: 40px;
  }
  .about-section {
    
    padding: 50px 0 23px;
}
.service-section {
 
  padding: 40px 0px;
}
.call-to-action {
  padding: 0px 0px;
}
section > .container, section > .container-fluid {
  padding-top: 40px;
  padding-bottom: 40px;
}
}
@media (min-width: 700px){
  .paddingclass{
 
    padding: 250px 70px;
   
  }
}



