

.p-relative {
    position: relative;
}
.pb-90 {
    padding-bottom: 90px;
}
.pt-120 {
    padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1200px){
.animations-01, .animations-02, .animations-03, .animations-04, .animations-05, .animations-06, .animations-07, .animations-08, .animations-09, .animations-10, .animations-11, .animations-11, .animations-12, .animations-13, .animations-14, .animations-15, .team-active .slick-arrow {
    display: none !important;
}}

.animations-01 {
    position: absolute;
    left: 0;
    top: 240px;
    animation: alltuchtopdown 3s infinite;
    -webkit-animation: alltuchtopdown 3s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 1s;
}
.img, img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
.services-08-item {
    padding: 40px;
    border-radius: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}
.mb-30 {
    margin-bottom: 30px;
}
.services-08-item:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: 1;
}


.services-icon2 {
    position: absolute;
    right: 5%;
    top: 0;
    z-index: 1;
    opacity: .05;
}
img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
.services-08-thumb {
    position: relative;
    z-index: 1;
}
.services-08-thumb img {
    height: 80px;
    margin-bottom: 20px;
}
.img, img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
.services-08-content, .services-08-thumb {
    position: relative;
    z-index: 1;
}
.services-08-content h3 {
    margin-bottom: 15px;
    font-size: 26px;
}
p, span {
    overflow-wrap: break-word;
}

p {
    margin-bottom: 15px;
}
a, h1, h2, h3, h4, h5, h6, p, span {
    overflow-wrap: break-word;
}
a, button {
    color: #101010;
    outline: medium none;
}
@media (max-width: 700px){
    .pt-120 {
        padding-top: 0px;
    }
    .pb-90{
        padding-bottom: 0px;
    }
}