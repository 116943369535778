.navbar {
  background-color: transparent;
  position: relative;
  z-index: 100;
}

.mid-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px 20px 0px 0px;
}

.navbar-nav {
  width: 100%;
  justify-content: center;
}

.navbar-nav a {
  color: #388d3b;
}


/* .LogoImage {
  height: 13vh;
  width: 25vh;
} */

.LogoImage img {
  height: 100%;
  width: 100%;
}


.navbar-nav a {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  line-height: 1em;
  padding: 12px 0;
  margin: 0 15px;
  letter-spacing: 2px;
  text-align: left;
}

.SmallSLogo{
  /* position: absolute; */
  right: 0;
  top: 0;
}
/* /////  PopUp Form ///  */


.btn:focus {
  box-shadow: none;
}

.Check-In {
  margin-top: 12px;
}

.Check-In h6 {
  font-size: 15px;
  font-weight: initial;
}

.Check-Out {
  margin-top: 12px;
}

.Check-Out h6 {
  font-size: 15px;
  font-weight: initial;
}

.form-label {
  margin: 5px 2px 2px 2px;
}

@media(max-width:991px){
  .navbar-brand{
      display: none;
  }
  .LogoImage img {
    height: 50%;
    width: 50%;
  }
  
  .navbar-collapse {
      background: linear-gradient(90deg,rgba(25,25,25,.8),rgba(25,25,25,.6));
      border: 2px solid rgb(186, 186, 186);
      margin-top: 8px;
      border-radius: 5px;
      position: absolute;
      top: 47px;
      z-index: 3;
      width: 80%;
      backdrop-filter: blur(8px);
  }
}

@media(min-width:991px){
  .SmallSLogo{
      display: none;
  }
  .LogoImage {
    height: 13vh;
    width: 25vh;
  }
  /* .LogoImage {
    height: none;
    width: 25vh;
    text-align: left;} */

 
}
.navbar-nav a{
  /* background-color: black; */
}
.btn:active, button:active, [role=button]:active, [type=submit]:active {
  
}



/* ////////////////////////////// */

.dorpdown {
  position: absolute;
  background: #fff;
  color: #111;
  border-radius: 5px;
  width: 100vh;
  top: 100px;
  
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transition: 0.5s ease-in-out;
  margin: 0px;
  /* overflow-y: scroll; */
}

.dropdown_list {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 10px;
}

.dropdown_list a {
  color: #111;
  font-size: 12px;
  text-transform: capitalize;
  padding: 5px 0px;
  text-decoration: none;
  color: var(---primeColor);
  margin: 0px;
  letter-spacing: normal;
}

.dropdown_list a:hover {
  color: var(---primaryColor);
}

.dropdownShow {
  background: unset;
  border: unset;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  color: white;
}

.dropdownShow:focus .dorpdown,
.dropdownShow:hover .dorpdown {
  top: 70px;
  visibility: visible;
  opacity: 1;
  z-index: 100;
  transition: 0.5s ease-in-out;
}


@media (max-width: 500px) {

  .dropdownShow:focus .dorpdown,
  .dropdownShow:hover .dorpdown {
      top: 180px;
      visibility: visible;
      opacity: 1;
      z-index: 100;
      transition: 0.5s ease-in-out;
  }

  .dropdownShow {
      padding: 0px;
  }

}

/* ////////////////////////////////////////////////// */

/*
*  STYLE 1
*/

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(---primeColor);
}

.navbardrop{
  display: flex;
  /* justify-content: center; */
  border-bottom: 1px solid rgb(211, 211, 211);
  border-right: 1px solid rgb(211, 211, 211);
  align-items: center;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: #388d3b !important;
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
}
.nav-link:focus, .nav-link:hover {
  color: #388d3b !important;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: black !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  color: black !important;
}


@media (max-width: 700px){

  .LogoImage {
    height: auto;
    width: 25vh;
    text-align: right;}

}
@media (min-width: 700px){

  .LogoImage {
    height: 13vh;
    width: 25vh;
  }

}